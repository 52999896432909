import { Select } from 'antd';

export default function SwitchStoreSelect() {
  const options = [
    {
      value: 'myStore',
      label: 'My Store',
    },
  ];

  return (
    <Select
      className="w-full"
      defaultValue="myStore"
      // onChange={handleChange}
      options={options}
    />
  );
}
