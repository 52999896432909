import type { FormInstance } from 'antd';
import { Form } from 'antd';
import type { CMSEditData } from 'views/content/model/cms';
import { mediaUploadMapper } from 'views/content/utils/mediaUploadMapper';

import { CmsFormItem } from '../CmsFormItem';

interface Props {
  data: CMSEditData[];
  form: FormInstance<{}>;
  formProps: any;
  keysPrefix?: string;
}

export default function CmsForm({ form, data, formProps, keysPrefix }: Props) {
  return (
    <Form
      {...formProps}
      className="max-w-6xl"
      layout="vertical"
      onFinish={(values: any) => {
        return formProps.onFinish?.(mediaUploadMapper(values));
      }}
    >
      {data.map((_data) => (
        <CmsFormItem
          key={_data.id}
          keys={
            keysPrefix ? [keysPrefix, String(_data.id)] : [String(_data.id)]
          }
          data={_data}
          form={form}
        />
      ))}
    </Form>
  );
}
