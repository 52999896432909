import type {
  BaseRecord,
  CreateManyResponse,
  CreateResponse,
  CrudFilter,
  CrudFilters,
  CrudSorting,
  CustomResponse,
  DataProvider as RefineDataProvider,
  DeleteManyResponse,
  DeleteOneResponse,
  GetListResponse,
  GetManyResponse,
  GetOneResponse,
  MetaDataQuery,
  Pagination,
  UpdateManyResponse,
  UpdateResponse,
} from '@pankod/refine-core';

export interface DataProvider {
  getList: (params: {
    resource: string;
    pagination?: Pagination;
    sort?: CrudSorting;
    filters?: CrudFilters;
    metaData?: MetaDataQuery;
  }) => Promise<GetListResponse<BaseRecord>>;
  getMany: (params: {
    resource: string;
    ids: string[];
    metaData?: MetaDataQuery;
  }) => Promise<GetManyResponse<BaseRecord>>;
  getOne: (params: {
    resource: string;
    id: string;
    metaData?: MetaDataQuery;
  }) => Promise<GetOneResponse<BaseRecord>>;
  create: <TVariables = {}>(params: {
    resource: string;
    variables: TVariables;
    metaData?: MetaDataQuery;
  }) => Promise<CreateResponse<BaseRecord>>;
  createMany: <TVariables = {}>(params: {
    resource: string;
    variables: TVariables[];
    metaData?: MetaDataQuery;
  }) => Promise<CreateManyResponse<BaseRecord>>;
  update: <TVariables = {}>(params: {
    resource: string;
    id: string;
    variables: TVariables;
    metaData?: MetaDataQuery;
  }) => Promise<UpdateResponse<BaseRecord>>;
  updateMany: <TVariables = {}>(params: {
    resource: string;
    ids: string[];
    variables: TVariables;
    metaData?: MetaDataQuery;
  }) => Promise<UpdateManyResponse<BaseRecord>>;
  deleteOne: (params: {
    resource: string;
    id: string;
    metaData?: MetaDataQuery;
  }) => Promise<DeleteOneResponse<BaseRecord>>;
  deleteMany: (params: {
    resource: string;
    ids: string[];
    metaData?: MetaDataQuery;
  }) => Promise<DeleteManyResponse<BaseRecord>>;
  getApiUrl: () => string;
  custom?: (params: {
    url: string;
    method: 'get' | 'delete' | 'head' | 'options' | 'post' | 'put' | 'patch';
    sort?: CrudSorting;
    filters?: CrudFilter[];
    payload?: {};
    query?: {};
    headers?: {};
    metaData?: MetaDataQuery;
  }) => Promise<CustomResponse<BaseRecord>>;
}
const customizableDataProviderKeys = [
  'getList',
  'getMany',
  'getOne',
  'create',
  'createMany',
  'update',
  'updateMany',
  'deleteOne',
  'deleteMany',
] as const;

type DataProviderKey = keyof DataProvider;
type CustomizableDataProviderKey =
  (typeof customizableDataProviderKeys)[number];
type DataProviderMethod<T extends DataProviderKey> = Required<DataProvider>[T];
type DataProviderCustomizableMethodParameters<
  T extends CustomizableDataProviderKey
> = Parameters<DataProviderMethod<T>>;

type CustomizationsType = Record<
  string,
  Partial<Pick<DataProvider, CustomizableDataProviderKey>>
>;

function keyIsCustomizable(key: any): key is CustomizableDataProviderKey {
  return customizableDataProviderKeys.includes(key);
}

export function customize(
  source: DataProvider | {},
  customizations?: CustomizationsType
): RefineDataProvider {
  const proxiedSource = new Proxy(source, {
    get: function proxyGetHandler<
      K extends DataProviderKey,
      R extends ReturnType<DataProviderMethod<K>>
    >(target: DataProvider, key: K) {
      if (!keyIsCustomizable(key)) {
        return target[key];
      }

      return function customizerHandler<
        M extends DataProviderCustomizableMethodParameters<typeof key>
      >(...params: M): R {
        const firstParam = params[0];

        const { resource } = firstParam;

        const customizedMethodToCall = customizations?.[resource]?.[key];

        if (typeof customizedMethodToCall === 'function') {
          return (customizedMethodToCall as any)(...params) as R;
        }

        const methodOnBase = target[key];
        const targetResponse: R = (methodOnBase as any)(...params);

        return targetResponse;
      };
    },
  });

  return proxiedSource as RefineDataProvider;
}
