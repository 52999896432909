import { TextField, Tooltip } from '@pankod/refine-antd';
import React from 'react';
import { shortenAddress } from 'utils/shortenAddress';

import { getChainById } from '../../utils/getChainById';

type AddressLinkProps = {
  address: string;
  chainId: number;

  alias?: string;
  short?: boolean;
};
export const AddressLink: React.FC<AddressLinkProps> = ({
  address,
  chainId,
  short = false,
  alias,
}) => {
  const explorerUrl = chainId
    ? getChainById(chainId)?.blockExplorers?.default.url
    : '';

  const addressUrl = `${explorerUrl}/address/${address}`;

  return (
    <Tooltip title={address}>
      <a target={'_blank'} href={addressUrl}>
        <TextField
          copyable
          value={alias || (short ? shortenAddress(address, true) : address)}
        />
      </a>
    </Tooltip>
  );
};
