import { Link } from '@pankod/refine-react-router-v6';
import { Button } from 'antd';

interface Props {
  title: string;
  paragraph: string;
  image: string;
  button: {
    text: string;
    onClick: () => void;
    href: string;
  };
}

export default function GuideContent({
  title,
  paragraph,
  image,
  button,
}: Props) {
  return (
    <div className="flex flex-col px-6 py-4">
      <h4 className="text-lg text-slate-800">{title}</h4>
      <p className="text-sm text-slate-400">{paragraph}</p>
      <img src={image} alt={title} />
      {/* <a href={learnMore.}>{learnMore.text}</a> */}
      <Link to={button.href}>
        <Button className="mt-4" type="primary" onClick={button.onClick}>
          {button.text}
        </Button>
      </Link>
    </div>
  );
}
