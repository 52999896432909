import {
  DateField,
  getDefaultSortOrder,
  List,
  Table,
  useTable,
} from '@pankod/refine-antd';
import React from 'react';

type DividendListProps = {
  distributionId: number;
};
export const DividendList: React.FC<DividendListProps> = ({
  distributionId,
}) => {
  const { tableProps, sorter } = useTable({
    dataProviderName: 'crud',
    resource: 'dividends',
    permanentFilter: [
      { field: 'distributionId', operator: 'eq', value: distributionId },
    ],
    initialSorter: [
      {
        field: 'payout',
        order: 'desc',
      },
    ],
  });
  return (
    <List
      breadcrumb={null}
      title={'Dividends Distribution List'}
      headerProps={{
        extra: <></>,
      }}
      resource="dividends"
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex={['member', 'name']} title="Name" />
        <Table.Column dataIndex="payout" title="Payout" />
        <Table.Column dataIndex="receivingMethod" title="Receiving Method" />
        <Table.Column
          dataIndex={['receivedAt']}
          title="Received At"
          sorter
          defaultSortOrder={getDefaultSortOrder('createdAt', sorter)}
          render={(value) => <DateField value={value} format="LLL" />}
        />
      </Table>
    </List>
  );
};
