import { Upload } from '@pankod/refine-antd';
import { useStrapiUpload } from '@pankod/refine-strapi-v4';

export default function UploadImage(args: any) {
  const accessToken = localStorage.getItem('accessToken');
  const { ...uploadProps } = useStrapiUpload({
    maxCount: args.multiple ? 10 : 1,
  });

  return (
    <Upload.Dragger
      name="files"
      action={`${process.env.REACT_APP_BASE_API_URL}/v1/admin/cms/upload`}
      headers={{
        Authorization: `Bearer ${accessToken}`,
        // method: 'POST',
      }}
      listType="picture"
      {...uploadProps}
      {...args}
    >
      <p className="ant-upload-text">Drag & drop a file in this area</p>
    </Upload.Dragger>
  );
}
