import type {
  ButtonProps,
  DrawerProps,
  FormInstance,
  FormProps,
} from '@pankod/refine-antd';
import {
  Create,
  DatePicker,
  Drawer,
  Form,
  Grid,
  InputNumber,
} from '@pankod/refine-antd';
import type { ResourceRouterParams } from '@pankod/refine-core';
import { useRouterContext } from '@pankod/refine-core';
import dayjs from 'dayjs';
import React from 'react';

type CreateDistributionProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  form: FormInstance;
  onFinish: (values: {}) => void;
};

export const CreateDistribution: React.FC<CreateDistributionProps> = ({
  drawerProps,
  formProps,
  saveButtonProps,
  form,
  onFinish,
}) => {
  const breakpoint = Grid.useBreakpoint();
  const { useParams } = useRouterContext();
  const { id: projectId } = useParams<ResourceRouterParams>();
  return (
    <Drawer
      {...drawerProps}
      title={'Create Distribution'}
      width={breakpoint.sm ? '500px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Create
        breadcrumb={null}
        goBack={false}
        title={<></>}
        headerProps={{
          extra: <></>,
        }}
        resource="distributions"
        saveButtonProps={{
          ...saveButtonProps,
          onClick: () => {
            const values = form.getFieldsValue(true);
            onFinish({ ...values, projectId });
          },
        }}
      >
        <Form {...formProps} layout="vertical">
          <Form.Item
            label={'Payout'}
            name="payout"
            rules={[
              {
                required: true,
                type: 'number',
              },
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              size="large"
              placeholder="USD"
              addonAfter="$"
            />
          </Form.Item>
          <Form.Item
            label={'Dividend Eligibility Deadline'}
            name={'dividendEligibilityDeadline'}
            rules={[
              {
                required: true,
              },
            ]}
            getValueProps={(value) => ({
              value: value ? dayjs(value) : '',
            })}
          >
            <DatePicker showTime />
          </Form.Item>
          <Form.Item
            label={'Schedule Distribution At'}
            name={'distributionScheduleAt'}
            rules={[
              {
                required: true,
              },
            ]}
            getValueProps={(value) => ({
              value: value ? dayjs(value) : '',
            })}
          >
            <DatePicker showTime />
          </Form.Item>
        </Form>
      </Create>
    </Drawer>
  );
};
