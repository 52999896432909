/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
// 1. 用 configuration 判斷欄位是否顯示
// 2. 從 content-type 判別欄位是不是 component
// 3. 用 configuration 判斷出 layout 位置與大小
// @ts-nocheck
import produce from 'immer';

import type { CMSEditData, CMSEditOptionData } from '../model/cms';

// 從 configuration 取得所有需要顯示的欄位
const getVisibleCmsEditOptionData = (metadatas) => {
  const dataWithKey = Object.keys(metadatas).map((key) => ({
    ...metadatas[key],
    id: key,
  }));
  const visibleData = Object.values(dataWithKey).filter(
    (_data) => _data?.edit?.visible
  );
  const visibleEditData = visibleData.map((_data) => ({
    ..._data.edit,
    id: _data.id,
  }));

  const cmsEditOptionData: CMSEditOptionData = visibleEditData.map((_data) => ({
    id: _data.id,
    label: _data.label,
    description: _data.description,
  }));
  return cmsEditOptionData;
};

// type isComponent repeatable
// 1. 從 content-type 給予 type
// 2. 判別欄位是不是 component
// 3. 判別欄位是不是 components dynamiczone 要用到
// 4. 判別欄位是不是陣列
const getCmsAttributes = (key: string, attributes) => {
  const hasKey = Object.keys(attributes).includes(key);
  if (hasKey) {
    return {
      type: attributes[key]?.type,
      component: attributes[key]?.component,
      components: attributes[key]?.components,
      repeatable: Boolean(
        attributes[key]?.repeatable || attributes[key]?.multiple
      ),
    };
  }
  return undefined;
};

// dynamiczone 用到 components 這邊要把 displayName 加上 這樣才能顯示
const getComponentsDisplayName = (data, components) => {
  const newComponents = data.components.map((_component) => {
    return {
      uid: _component,
      displayName: components.find((c) => c.uid === _component)?.info
        .displayName,
    };
  });

  return {
    ...data,
    components: newComponents,
  };
};

// 設定 layout order
const getCmsLayout = (data, layoutsEdit) => {
  let order = 0;
  const newData = produce(data, (draft) => {
    layoutsEdit.forEach((row) => {
      row.forEach((item) => {
        const cmsData = draft.find((_data) => _data.id === item.name);
        cmsData.layout = { order };
        order += 1;
      });
    });
  });

  return newData;
};

// 按照 order 條列式顯示，這部分和 strapi 不一樣
const sortCmsData = (data: CMSEditData[]) => {
  data.forEach((_data) => {
    if (_data.component === 'component') {
      sortCmsData(_data.value);
    }
  });
  data.sort((a, b) => {
    return a.layout.order - b.layout.order;
  });
};

const cmsDataFormat = ({
  contentTypeAttributes,
  contentType,
  contentTypeLayout,
  components,
  componentsConfiguration,
}) => {
  const visibleData = getVisibleCmsEditOptionData(contentTypeAttributes);
  const attributesData = visibleData.map((_data) => {
    let attributes = getCmsAttributes(_data.id, contentType.attributes);
    if (attributes && attributes.components) {
      attributes = getComponentsDisplayName(attributes, components.data);
    }
    return {
      ..._data,
      ...attributes,
    };
  });

  const layoutsData = getCmsLayout(attributesData, contentTypeLayout);

  const data = layoutsData.map((_data) => {
    if (_data.component) {
      const contentTypeData = components.data.find(
        (_contentType) => _contentType.uid === _data.component
      );
      const formatData = cmsDataFormat({
        contentTypeAttributes:
          componentsConfiguration[_data.component].metadatas,
        contentType: contentTypeData,
        contentTypeLayout:
          componentsConfiguration[_data.component].layouts.edit,
        components,
        componentsConfiguration,
      });
      return { ..._data, value: formatData };
    }
    if (_data.components) {
      const formatDataList = _data.components.map((_component) => {
        const contentTypeData = components.data.find(
          (_contentType) => _contentType.uid === _component.uid
        );
        const formatData = cmsDataFormat({
          contentTypeAttributes:
            componentsConfiguration[_component.uid].metadatas,
          contentType: contentTypeData,
          contentTypeLayout:
            componentsConfiguration[_component.uid].layouts.edit,
          components,
          componentsConfiguration,
        });
        return formatData;
      });
      return { ..._data, value: formatDataList };
    }
    return _data;
  });

  sortCmsData(data);

  return data;
};

export const prepareCmsFormData = ({
  contentType,
  configuration,
  components,
}) => {
  const contentTypeAttributes = configuration.contentType.metadatas;
  const contentTypeLayout = configuration.contentType.layouts.edit;

  const dataFormat = cmsDataFormat({
    contentTypeAttributes,
    contentTypeLayout,
    contentType,
    components,
    componentsConfiguration: configuration.components,
  });

  // console.log(components, configuration, contentType);

  // const mockContentType = mockContentTypes.data.find(
  //   (_data) => _data.uid === 'api::project.project'
  // );

  // const { metadatas } = mockConfiguration.contentType;
  // const componentLayoutEdit = mockConfiguration.contentType.layouts.edit;

  // const dataFormat = cmsDataFormat({
  //   contentTypeAttributes: metadatas,
  //   contentTypeLayout: componentLayoutEdit,
  //   contentType: mockContentType,
  //   components: mockComponents,
  //   componentsConfiguration: mockConfiguration.components,
  // });

  return dataFormat as CMSEditData[];
};
