import { EyeOutlined } from '@ant-design/icons';
import { Button } from 'antd';

interface Props {
  className?: string;
}

// TODO: 之後需要從 api 拿資料
const websiteUrl = 'https://talfa.tokenbricks-dev.com';

export default function ViewStoreButton({ className }: Props) {
  return (
    <Button
      className={className}
      type="text"
      icon={<EyeOutlined />}
      href={websiteUrl}
      target="_blank"
    >
      View store
    </Button>
  );
}
