import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  useSelect,
} from '@pankod/refine-antd';
import type { Chain } from '@tokenbricks/talfa-backend-typescript-axios-client';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';

import CmsForm from '../../content/containers/CmsForm';
import { prepareCmsFormData } from '../../content/utils/prepareCmsFormData';

// import { ethers } from 'ethers';

// import { numberFormat } from '../../../utils/numberFormat';

const { RangePicker } = DatePicker;

export function useGetFormList({
  content,
  componentIds,
  contentType,
  config,
  components,
  form,
  formProps,
}: {
  content: any;
  componentIds: string[];
  contentType: any;
  config: any;
  components: any;
  form: any;
  formProps: any;
}) {
  const { selectProps: supportedChainsProps } = useSelect<Chain>({
    dataProviderName: 'crud',
    resource: 'chains',
    optionValue: 'chainId',
    optionLabel: 'name',
  });

  const cmsFormData = useMemo(() => {
    if (
      components &&
      contentType &&
      content &&
      config &&
      componentIds.length === components.data.length
    ) {
      return prepareCmsFormData({
        contentType,
        configuration: config,
        components,
      });
    }
    return undefined;
  }, [contentType, config, components, content]);

  return [
    <>
      <CmsForm
        data={cmsFormData ?? []}
        form={form}
        formProps={formProps}
        keysPrefix={'content'}
      />
    </>,
    // Sale
    <>
      <Form.Item
        label={'Price Per Token'}
        name={['project', 'price']}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <InputNumber
          style={{ width: '100%' }}
          size="large"
          placeholder="USD"
          // formatter={(value) => (value ? numberFormat(value) : value)}
          // parser={(value) => (value ? 2 : 1)}
          addonAfter="$"
          // onChange={(value) => console.log(value)}
        />
      </Form.Item>
      <Form.Item
        label={'Minimum Purchase Amount'}
        name={['project', 'minimumPurchaseAmount']}
        rules={[
          {
            required: true,
          },
          {
            min: 1,
            type: 'integer',
            transform(value) {
              return Number(value);
            },
          },
        ]}
        getValueProps={(value) => ({
          value: value ? Number(value) : null,
        })}
      >
        <InputNumber style={{ width: '100%' }} size="large" />
      </Form.Item>

      <Form.Item
        label={'Sale Between'}
        name={['project', 'securityToken', 'tokenSale', 'saleBetween']}
        rules={[
          {
            required: true,
          },
        ]}
        getValueProps={(value) => {
          return {
            value: value ? value.map((_value: string) => dayjs(_value)) : '',
          };
        }}
      >
        <RangePicker showTime />
      </Form.Item>
    </>,
    // Token
    <>
      <Form.Item
        label={'Chain'}
        name={['project', 'securityToken', 'chainId']}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          {...supportedChainsProps}
          // onSelect={(value) => {
          //   console.log(value);
          // }}
        />
      </Form.Item>
      <Form.Item
        label={'Type'}
        name={['project', 'type']}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select options={[{ label: 'Equity', value: 'EQUITY' }]} />
      </Form.Item>
      <Form.Item
        label={'Token Name'}
        name={['project', 'securityToken', 'name']}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Bitcoin" />
      </Form.Item>
      <Form.Item
        label={'Token Symbol'}
        name={['project', 'securityToken', 'symbol']}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="BTC" />
      </Form.Item>
      <Form.Item
        label={'Token Standard'}
        name={['project', 'securityToken', 'standard']}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select options={[{ label: 'ERC20', value: 'erc20' }]} />
      </Form.Item>
      <Form.Item
        label={'Decimals'}
        name={['project', 'securityToken', 'decimals']}
        rules={[
          {
            required: true,
          },
          {
            type: 'integer',
            min: 0,
            max: 18,
            transform(value) {
              return Number(value);
            },
          },
        ]}
      >
        <InputNumber style={{ width: '100%' }} size="large" placeholder="18" />
      </Form.Item>
      {/* <Form.Item */}
      {/*  label={'Max Supply'} */}
      {/*  name={['project', 'securityToken', 'maxSupply']} */}
      {/*  rules={[ */}
      {/*    { */}
      {/*      required: true, */}
      {/*    }, */}
      {/*  ]} */}
      {/* > */}
      {/*  <InputNumber */}
      {/*    stringMode */}
      {/*    style={{ width: '100%' }} */}
      {/*    size="large" */}
      {/*    formatter={(value) => (value ? ethers.utils.commify(value) : '')} */}
      {/*    // suffix={'ddd'} */}
      {/*    addonAfter={ */}
      {/*      <> */}
      {/*        {(() => { */}
      {/*          const decimals = Number( */}
      {/*            form.getFieldValue(['project', 'securityToken', 'decimals']) */}
      {/*          ); */}
      {/*          const number = Number('1'.padEnd(decimals + 1, '0')); */}
      {/*          console.log('number', number, form); */}
      {/*          return ( */}
      {/*            ethers.utils.commify(number).split('1')[1] +*/}
      {/*            Intl.NumberFormat('en', { notation: 'compact' }).format( */}
      {/*              number */}
      {/*            ) */}
      {/*          ); */}
      {/*        })()} */}
      {/*      </> */}
      {/*    } */}
      {/*  /> */}
      {/* </Form.Item> */}

      <Form.Item
        label={'Minimum Holding Period (days)'}
        name={[
          'project',
          'securityToken',
          'tokenSale',
          'initialOfferingLockSecond',
        ]}
        rules={[
          {
            required: true,
          },
          {
            type: 'integer',
            min: 0,
          },
        ]}
      >
        <InputNumber
          style={{ width: '100%' }}
          addonAfter="Days"
          placeholder="365"
        />
      </Form.Item>
    </>,
  ];
}
