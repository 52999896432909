import { notificationProvider } from '@pankod/refine-antd';
import type { NotificationProvider } from '@pankod/refine-core';

export const customNotificationProvider: NotificationProvider = {
  open: (args) => {
    // TODO: cms 在專案初始化時會404
    if (
      args.description?.includes(
        'Cannot GET /api/v1/admin/cms/content-manager/single-types/'
      )
    ) {
      return;
    }
    if (args.description === 'Unauthorized') {
      return;
    }
    notificationProvider.open(args);
  },
  close: (key) => {
    notificationProvider.close(key);
  },
};
