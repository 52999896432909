import type {
  GnosisConnectorArguments,
  GnosisSafeConnector,
} from 'connectors/gnosis-safe';
import { useAccount, useConnect } from 'wagmi';

/**
 * Hook for connecting to a Gnosis Safe. This enables multisig wallets to connect to your application and sing transactions.
 *
 * @example
 * ```javascript
 * import { useGnosis } from "@thirdweb-dev/react"
 *
 * const App = () => {
 *   const connectWithGnosis = useGnosis()
 *
 *   return (
 *     <button onClick={() => connectWithGnosis({ safeAddress: "0x...", safeChainId: 1 })}>
 *       Connect Gnosis Safe
 *     </button>
 *   )
 * }
 * ```
 *
 * @public
 */

export function useGnosisSafe() {
  // invariant(
  //   wagmiContext,
  //   `useGnosis() can only be used inside <ThirdwebProvider />. If you are using <ThirdwebSDKProvider /> you will have to use your own wallet-connection logic.`,
  // );
  const { connector: activeConnector /* , isConnected, address */ } =
    useAccount();
  const { connectAsync, isLoading, connectors } = useConnect();

  if (isLoading) {
    return () =>
      Promise.reject(new Error('Gnosis connector not ready to be used, yet'));
  }
  const connector = connectors.find((c) => c.id === 'gnosis-safe');
  // invariant(
  //   connector,
  //   'Gnosis connector not found, please make sure it is provided to your <ThirdwebProvider />'
  // );

  return async (config: GnosisConnectorArguments) => {
    const activeConnectorChainId = await activeConnector?.getChainId();
    // invariant(
    //   !!previousConnector,
    //   'Cannot connect to Gnosis Safe without first being connected to a personal wallet.'
    // );
    // invariant(
    //   previousConnectorChain === config.safeChainId,
    //   'Gnosis safe chain id must match personal wallet chain id.'
    // );
    // invariant(
    //   utils.isAddress(config.safeAddress),
    //   'Gnosis safe address must be a valid address.'
    // );
    (connector as GnosisSafeConnector).setConfiguration(
      activeConnector!,
      config
    );

    return connectAsync({ connector, chainId: activeConnectorChainId });
  };
}
