import { EditButton, List, Space, Table, useTable } from '@pankod/refine-antd';
import type { HttpError, IResourceComponentsProps } from '@pankod/refine-core';

export const PageList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<any, HttpError, {}>({
    dataProviderName: 'strapiV4ContentManager',
    resource: 'content-types',
    hasPagination: false,
    permanentFilter: [
      {
        field: 'kind',
        operator: 'eq',
        value: 'singleType',
      },
    ],
  });

  return (
    <List>
      <Table {...tableProps} rowKey="uid">
        <Table.Column dataIndex={['info', 'displayName']} title="Page" />
        {/* <Table.Column dataIndex={'uid'} title="uid" /> */}
        <Table.Column dataIndex={['info', 'description']} title="Description" />
        <Table.Column<{ uid: string }>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.uid} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
