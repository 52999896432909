import { useEffect, useState } from 'react';
import { useAccount, useClient, useConnect } from 'wagmi';

import type { GnosisSafeConnector } from '../connectors/gnosis-safe';

function useAutoConnectGnosisSafe() {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [forceUpdate, setForceUpdate] = useState<number>(0);
  const { connectors, connect } = useConnect();
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { connector } = useAccount();
  const client = useClient();

  useEffect(() => {
    const autoConnect = async () => {
      const lastUsedConnectorId = client.storage.getItem('wallet');
      if (lastUsedConnectorId !== 'gnosis-safe') return;
      const gnosisSafeConnector = connectors.find(
        (c) => c.id === 'gnosis-safe'
      ) as GnosisSafeConnector;

      if (!gnosisSafeConnector.previousConnectorId) {
        return;
      }
      const previousConnector = connectors.find(
        (c) => c.id === gnosisSafeConnector.previousConnectorId
      );

      if (!previousConnector) {
        return;
      }
      // if (connector?.id === 'gnosis-safe') {
      //   return;
      // }
      gnosisSafeConnector.setConnector(previousConnector!);
      const chainId = await previousConnector.getChainId();
      connect({ connector: gnosisSafeConnector, chainId });
    };
    setTimeout(async () => {
      await autoConnect();
      setForceUpdate((f) => f + 1);
    }, 0);
  }, [connect, connectors]);
}

export { useAutoConnectGnosisSafe };
