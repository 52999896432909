// TODO: 之後可能需要重構，搬進 react 生命週期裡面
import {
  adminAuthenticationApi,
  coreBackendDefHttp,
} from 'api/coreBackend/defHttp';
import type { JwtPayload } from 'jwt-decode';
import jwt_decode from 'jwt-decode';

const resetAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    return false;
  }
  coreBackendDefHttp.defaults.headers.common.Authorization = `Bearer ${refreshToken}`;
  const { data } = await adminAuthenticationApi.adminRefreshToken();
  coreBackendDefHttp.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
  localStorage.setItem('accessToken', data.accessToken);
  localStorage.setItem('refreshToken', data.refreshToken);

  // const dom = document.getElementById('iframe') as any;
  // const win = dom.contentWindow;

  // win.postMessage(
  //   JSON.stringify({
  //     jwtToken: data.accessToken,
  //   }),
  //   process.env.REACT_APP_STRAPI_URL
  // );
  return true;
};

let timeoutTimer: NodeJS.Timeout;
let intervalTimer: NodeJS.Timeout;

export const refreshAuthToken = () => {
  // console.log('refreshAuthToken');
  clearTimeout(timeoutTimer);
  clearInterval(intervalTimer);
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) return;

  const jwtDecoded = jwt_decode<JwtPayload>(accessToken);
  const accessTokenExpires = jwtDecoded.exp;
  const accessTokenIssuedAt = jwtDecoded.iat;
  if (accessTokenExpires && accessTokenIssuedAt) {
    const refreshTime = accessTokenExpires - accessTokenIssuedAt;
    const firstRefreshTime = Math.round(accessTokenExpires - Date.now() / 1000);
    timeoutTimer = setTimeout(() => {
      // console.log('setTimeout');

      resetAccessToken();
      intervalTimer = setInterval(async () => {
        // console.log('setInterval');
        const isLogin = await resetAccessToken();
        // console.log('isLogin', isLogin);
        if (!isLogin) {
          clearInterval(intervalTimer);
        }
      }, (refreshTime * 1000) / 2);
      // }, 5000);
    }, (firstRefreshTime * 1000) / 2);
    // }, 5000);
  }
  // console.log('accessTokenExpires', accessTokenExpires);
  // console.log('jwtDecoded', jwtDecoded);
};
