import { Edit, useForm } from '@pankod/refine-antd';
import type { IResourceComponentsProps } from '@pankod/refine-core';
import { useMany, useOne } from '@pankod/refine-core';
import type { ContentTypeData } from 'interfaces/contentType';
import type { ContentTypesConfiguration } from 'interfaces/strapi';
import React, { useMemo } from 'react';
import CmsForm from 'views/content/containers/CmsForm';
import { prepareCmsFormData } from 'views/content/utils/prepareCmsFormData';

export const PageEdit: React.FC<IResourceComponentsProps> = () => {
  const { form, formProps, saveButtonProps, queryResult, id, formLoading } =
    useForm<any>();

  const { data: contentTypeData } = useOne<ContentTypeData>({
    dataProviderName: 'strapiV4ContentManager',
    resource: 'content-types',
    id: id!,
  });

  const { data, isLoading: isConfigLoading } =
    useOne<ContentTypesConfiguration>({
      dataProviderName: 'strapiV4ContentManager',
      resource: 'content-types/configuration',
      id: id!,
    });

  const contentType = contentTypeData?.data;
  const content = queryResult?.data?.data;
  const config = data?.data?.data;

  const componentIds = Object.keys(config?.components ?? {});
  const { data: components } = useMany({
    dataProviderName: 'strapiV4ContentManager',
    resource: 'components',
    ids: componentIds,
  });

  const cmsFormData = useMemo(() => {
    if (
      components &&
      contentType &&
      config &&
      componentIds.length === components.data.length
    ) {
      return prepareCmsFormData({
        contentType,
        configuration: config,
        components,
      });
    }
    return [];
  }, [contentType, config, components, content]);

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      isLoading={formLoading && isConfigLoading}
    >
      <CmsForm data={cmsFormData} form={form} formProps={formProps} />
    </Edit>
  );
};
