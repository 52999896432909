import type { RefineLayoutLayoutProps } from '@pankod/refine-antd';
import {
  AntdLayout,
  Grid,
  Header as DefaultHeader,
  Sider as DefaultSider,
} from '@pankod/refine-antd';
import React from 'react';

export const CustomLayout: React.FC<RefineLayoutLayoutProps> = ({
  children,
  Header,
  Sider,
  Footer,
  OffLayoutArea,
}) => {
  const breakpoint = Grid.useBreakpoint();
  const SiderToRender = Sider ?? DefaultSider;
  const HeaderToRender = Header ?? DefaultHeader;
  const isSmall = typeof breakpoint.sm === 'undefined' ? true : breakpoint.sm;

  return (
    <AntdLayout style={{ minHeight: '100vh' }}>
      <HeaderToRender />
      <AntdLayout>
        <SiderToRender />
        <AntdLayout.Content>
          <div
            style={{
              minHeight: 360,
              padding: isSmall ? 24 : 12,
            }}
          >
            {children}
          </div>
          {OffLayoutArea && <OffLayoutArea />}
        </AntdLayout.Content>
        {Footer && <Footer />}
      </AntdLayout>
    </AntdLayout>
  );
};
