import '../style/styles.css';

import {
  AntdLayout,
  Button,
  Card,
  Col,
  Form,
  Input,
  notificationProvider,
  Row,
  Typography,
} from '@pankod/refine-antd';
import { useMutation, useRouterContext } from '@pankod/refine-core';
import type { CreatePasswordDto } from '@tokenbricks/talfa-backend-typescript-axios-client';
import { adminAuthenticationApi } from 'api/coreBackend/defHttp';
import React from 'react';

const { Title } = Typography;

type FormValues = Omit<CreatePasswordDto, 'token'>;

export const CreatePassword: React.FC = () => {
  const { useLocation } = useRouterContext();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get('token');

  const [form] = Form.useForm<FormValues>();
  const { mutate } = useMutation(
    (data: CreatePasswordDto) => {
      return adminAuthenticationApi.adminSetPassword(data);
    },
    {
      onSuccess: () => {
        notificationProvider.open({
          type: 'success',
          message: 'Successfully created your password. You can now login.',
        });
        setTimeout(() => {
          window.location.href = '/auth/login';
        }, 2000);
      },
      onError: (error: { message: string }) => {
        notificationProvider.open({
          type: 'error',
          message: error.message,
        });
      },
    }
  );

  const CardTitle = (
    <Title level={3} className="title">
      Set your password
    </Title>
  );

  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <img
                className="h-52"
                src="/images/logo/logo-white.svg"
                alt="talfa Logo"
              />
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<FormValues>
                layout="vertical"
                form={form}
                onFinish={(values) => {
                  if (token) {
                    mutate({
                      newPassword: values.newPassword,
                      newPasswordConfirmation: values.newPasswordConfirmation,
                      token,
                    });
                  }
                }}
                requiredMark={false}
                initialValues={{
                  remember: false,
                }}
              >
                <Form.Item
                  name="newPassword"
                  label="Password"
                  rules={[{ required: true }]}
                >
                  <Input size="large" type="password" placeholder="Password" />
                </Form.Item>
                <Form.Item
                  name="newPasswordConfirmation"
                  label="Confirm Password"
                  rules={[{ required: true }]}
                >
                  <Input
                    size="large"
                    type="password"
                    placeholder="Confirm Password"
                  />
                </Form.Item>
                <Button type="primary" size="large" htmlType="submit" block>
                  Submit
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
