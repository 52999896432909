import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DateField,
  DatePicker,
  Form,
  getDefaultSortOrder,
  Input,
  List,
  Row,
  Select,
  ShowButton,
  Space,
  Table,
  TextField,
  useTable,
} from '@pankod/refine-antd';
import type {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
} from '@pankod/refine-core';
import type {
  Member,
  MemberDocument,
} from '@tokenbricks/talfa-backend-typescript-axios-client';
import type { Dayjs } from 'dayjs';

const { RangePicker } = DatePicker;

export const DocumentList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter, searchFormProps } = useTable<
    MemberDocument,
    HttpError,
    {
      keyword: string;
      status: string[];
      createdAt: [Dayjs, Dayjs];
    }
  >({
    dataProviderName: 'crud',
    resource: 'member-documents',
    initialSorter: [
      {
        field: 'updatedAt',
        order: 'desc',
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { keyword, createdAt, status } = params;
      filters.push(
        {
          operator: 'or',
          value: [
            {
              field: 'member.email',
              operator: 'contains',
              value: keyword || undefined,
            },
            {
              field: 'member.name',
              operator: 'contains',
              value: keyword || undefined,
            },
          ],
        },
        {
          field: 'status',
          operator: 'in',
          value: status && status.length > 0 ? status : undefined,
        },
        {
          field: 'createdAt',
          operator: 'gte',
          value: createdAt ? createdAt[0].toISOString() : undefined,
        },
        {
          field: 'createdAt',
          operator: 'lte',
          value: createdAt ? createdAt[1].toISOString() : undefined,
        }
      );

      return filters;
    },
  });
  return (
    <List>
      <Row gutter={[16, 16]}>
        <Col lg={6} xs={24}>
          <Card title="Filters">
            <Form layout="vertical" {...searchFormProps}>
              <Form.Item label="Search" name="keyword">
                <Input
                  allowClear
                  placeholder="User Name or Email"
                  prefix={<SearchOutlined />}
                />
              </Form.Item>
              <Form.Item label="Status" name="status">
                <Select
                  allowClear
                  mode="multiple"
                  options={[
                    // TODO from api
                    {
                      label: 'IN PROGRESS',
                      value: 'IN_PROGRESS',
                    },
                    {
                      label: 'COMPLETED',
                      value: 'COMPLETED',
                    },
                  ]}
                  placeholder="Status"
                />
              </Form.Item>
              <Form.Item label="Created At" name="createdAt">
                <RangePicker />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Filter
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col lg={18} xs={24}>
          <Table {...tableProps} rowKey="id">
            <Table.Column dataIndex={['member', 'name']} title="User Name" />
            <Table.Column dataIndex={['member', 'email']} title="Email" />
            <Table.Column dataIndex={['project', 'name']} title="Project" />
            <Table.Column
              dataIndex={['status']}
              title="status"
              render={(value) => (
                <TextField value={value.replaceAll('_', ' ')} />
              )}
            />
            <Table.Column
              dataIndex="updatedAt"
              title="Updated At"
              key="updatedAt"
              sorter
              defaultSortOrder={getDefaultSortOrder('updatedAt', sorter)}
              render={(value) => <DateField value={value} format="LLL" />}
            />
            <Table.Column
              dataIndex="createdAt"
              title="Created At"
              key="createdAt"
              sorter
              render={(value) => <DateField value={value} format="LLL" />}
            />
            <Table.Column<Member>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <ShowButton hideText size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </Col>
      </Row>
    </List>
  );
};
