import { CloseOutlined } from '@ant-design/icons';
import {
  Button,
  DateField,
  DeleteButton,
  EditButton,
  Form,
  getDefaultSortOrder,
  Input,
  List,
  SaveButton,
  Space,
  Table,
  TextField,
  useEditableTable,
} from '@pankod/refine-antd';
import type { IResourceComponentsProps } from '@pankod/refine-core';
import type { Wallet } from '@tokenbricks/talfa-backend-typescript-axios-client';
import { WalletListAoOwnerTypeEnum } from '@tokenbricks/talfa-backend-typescript-axios-client/dist/api';

export const WalletList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps,
    formProps,
    isEditing,
    sorter,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
  } = useEditableTable<Wallet>({
    permanentFilter: [
      {
        field: 'ownerType',
        operator: 'eq',
        value: WalletListAoOwnerTypeEnum.ADMIN,
      },
    ],
    initialSorter: [
      {
        field: 'updatedAt',
        order: 'desc',
      },
    ],
  });

  return (
    <List>
      <Form {...formProps}>
        <Table {...tableProps} rowKey="id">
          <Table.Column<Wallet>
            dataIndex="name"
            title="Name"
            render={(value, record) => {
              if (isEditing(record.id)) {
                return (
                  <Form.Item name="name" style={{ margin: 0 }}>
                    <Input />
                  </Form.Item>
                );
              }
              return <TextField value={value} />;
            }}
          />
          <Table.Column dataIndex="address" title="Address" />
          <Table.Column dataIndex="type" title="Type" />
          {/* <Table.Column dataIndex="chainId" title="Chain ID" /> */}
          <Table.Column
            dataIndex="updatedAt"
            title="Updated At"
            key="updatedAt"
            sorter
            defaultSortOrder={getDefaultSortOrder('updatedAt', sorter)}
            render={(value) => <DateField value={value} format="LLL" />}
          />
          <Table.Column
            dataIndex="createdAt"
            title="Created At"
            key="createdAt"
            sorter
            // defaultSortOrder={getDefaultSortOrder('createdAt', sorter)}
            render={(value) => <DateField value={value} format="LLL" />}
          />
          <Table.Column<Wallet>
            title="Actions"
            dataIndex="actions"
            render={(_, record) => {
              if (isEditing(record.id)) {
                return (
                  <Space>
                    <SaveButton hideText {...saveButtonProps} size="small" />
                    <Button
                      {...cancelButtonProps}
                      size="small"
                      icon={<CloseOutlined />}
                    />
                  </Space>
                );
              }
              return (
                <Space>
                  <EditButton
                    hideText
                    {...editButtonProps(record.id)}
                    size="small"
                  />
                  <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                </Space>
              );
            }}
          />
        </Table>
      </Form>
    </List>
  );
};
