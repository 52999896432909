import { CheckOutlined, CloseOutlined, MailOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  notificationProvider,
  Row,
  Show,
  Space,
  Typography,
} from '@pankod/refine-antd';
import type {
  IResourceComponentsProps,
  ResourceRouterParams,
} from '@pankod/refine-core';
import { useMutation, useRouterContext, useShow } from '@pankod/refine-core';
import type { AdminApplication } from '@tokenbricks/talfa-backend-typescript-axios-client';
import { adminApplicationManagementAdminApi } from 'api/coreBackend/defHttp';

const { Text } = Typography;

export const SponsorShow: React.FC<IResourceComponentsProps> = () => {
  const { useParams } = useRouterContext();
  const { id } = useParams<ResourceRouterParams>();
  const { queryResult } = useShow<AdminApplication>();

  const resendEmailMutation = useMutation(
    (_id: number) => {
      return adminApplicationManagementAdminApi.resendAdminApplicationInvitation(
        _id,
        { callbackPath: '/auth/register' }
      );
    },
    {
      onSuccess: () => {
        notificationProvider.open({
          type: 'success',
          message: 'resend email success!',
        });
      },
    }
  );

  const approveMutation = useMutation(
    (_id: number) => {
      return adminApplicationManagementAdminApi.updateAdminApplicationStatusApproval(
        _id,
        { callbackPath: '/auth/create-password' }
      );
    },
    {
      onSettled: () => {
        queryResult.refetch();
      },
    }
  );

  const rejectMutation = useMutation(
    (_id: number) => {
      return adminApplicationManagementAdminApi.updateAdminApplicationStatusRejection(
        _id
      );
    },
    {
      onSettled: () => {
        queryResult.refetch();
      },
    }
  );

  return (
    <Show
      isLoading={queryResult?.isFetching}
      headerButtons={({ defaultButtons }) => (
        <>
          <Button
            hidden={queryResult.data?.data.status !== 'PENDING_SUBMIT'}
            icon={<MailOutlined />}
            onClick={() => {
              resendEmailMutation.mutate(Number(id));
            }}
            loading={resendEmailMutation.isLoading}
          >
            Resend Email
          </Button>
          {defaultButtons}
        </>
      )}
      footerButtons={
        queryResult.data?.data.status === 'PENDING_REVIEW' &&
        (() => (
          <>
            <Button
              icon={<CloseOutlined style={{ color: '#ff0000' }} />}
              loading={rejectMutation.isLoading || approveMutation.isLoading}
              onClick={() => {
                rejectMutation.mutate(Number(id));
              }}
            >
              Reject
            </Button>
            <Button
              icon={<CheckOutlined style={{ color: '#8Fd15f' }} />}
              loading={rejectMutation.isLoading || approveMutation.isLoading}
              onClick={() => {
                approveMutation.mutate(Number(id));
              }}
            >
              Approve
            </Button>
          </>
        ))
      }
    >
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Email: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{queryResult.data?.data.email}</Text>
          </Space>
        </Col>
      </Row>

      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Status: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{queryResult.data?.data.status}</Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Name: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>
              {(
                queryResult.data?.data as unknown as {
                  data: { name: string };
                }
              )?.data?.name ?? '--'}
            </Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Created At: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{queryResult.data?.data.createdAt}</Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Updated At: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{queryResult.data?.data.updatedAt}</Text>
          </Space>
        </Col>
      </Row>
    </Show>
  );
};
