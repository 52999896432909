import SupportedChainProvider from 'containers/SupportedChainProvider';
import { WagmiConfig } from 'wagmi';

interface Props {
  children: React.ReactNode;
}

export default function DynamicChainProvider({ children }: Props) {
  return (
    <SupportedChainProvider>
      {(wagmiClient) => {
        return (
          wagmiClient && (
            <WagmiConfig client={wagmiClient}>{children}</WagmiConfig>
          )
        );
      }}
    </SupportedChainProvider>
  );
}
