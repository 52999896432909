import { goerli, hardhat, mainnet, polygon, polygonMumbai } from 'wagmi/chains';

export const getChainImage = (chainId?: number) => {
  switch (chainId) {
    case mainnet.id:
    case goerli.id:
      return '/images/chains/ethereum-logo.png';
    case polygon.id:
    case polygonMumbai.id:
      return '/images/chains/polygon-logo.png';
    case hardhat.id:
      return '/images/chains/hardhat-logo.png';
    default:
      return '/images/chains/meta-mask.svg';
  }
};
