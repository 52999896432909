import {
  Create,
  Form,
  Input,
  InputNumber,
  Select,
  useForm,
} from '@pankod/refine-antd';
import type { IResourceComponentsProps } from '@pankod/refine-core';
import type { Chain } from '@tokenbricks/talfa-backend-typescript-axios-client';
import React from 'react';
import * as allChains from 'wagmi/chains';

export const ChainCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<Chain>();

  const selectOptions = Object.values(allChains).map((c) => ({
    label: c.name,
    value: c.name,
  }));
  const handleSelect = (name: string) => {
    const chain = Object.values(allChains).find((c) => c.name === name);
    formProps.form?.setFieldValue('chainId', chain?.id);
  };
  return (
    <Create saveButtonProps={{ ...saveButtonProps }}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Chain"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select showSearch onSelect={handleSelect} options={selectOptions} />
        </Form.Item>
        <Form.Item
          hidden
          label="Chain ID"
          name="chainId"
          rules={[
            {
              required: true,
            },
          ]}
        ></Form.Item>
        <Form.Item
          label="Alchemy API Key"
          name="alchemyApiKey"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Block Confirmations"
          name="blockConfirmations"
          rules={[
            {
              required: true,
              type: 'number',
              min: 1,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Create>
  );
};
