import { DeleteOutlined, HolderOutlined } from '@ant-design/icons';
import { Button, Collapse, Tooltip } from 'antd';
import classNames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';

import classes from './style/index.module.scss';

const { Panel } = Collapse;

interface Props {
  move: (from: number, to: number) => void;
  item: {
    id: number | string;
    index: number;
    name: string;
    content: React.ReactNode;
  };
  remove: (index: number | number[]) => void;
  find: (id: number | string) => { index: number };
}

export default function ItemDnd(args: Props) {
  const { move, item, remove, find, ...rest } = args;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'box',
      item: { id: item.id },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      // end: (_item, monitor) => {
      //   const { id: droppedId } = _item;
      //   const didDrop = monitor.didDrop();
      //   console.log('_item', _item);
      //   console.log('didDrop', didDrop);

      //   if (!didDrop) {
      //     move(findItem(item.id).index, findItem(droppedId).index);
      //   }
      // },
    }),
    [item.id, move]
  );

  const [, drop] = useDrop(
    () => ({
      accept: 'box',
      hover({ id: draggedId }: any) {
        if (draggedId !== item.id) {
          move(find(draggedId).index, find(item.id).index);
        }
      },
    }),
    [find, move, item]
  );

  const opacity = isDragging ? 0 : 1;

  return (
    <Panel
      {...rest}
      key={item.id}
      header={
        <div
          ref={(node) => drag(drop(node))}
          className={classNames('flex items-center h-full relative px-4 py-3')}
          style={{ opacity }}
        >
          {item.name}
          <Button
            ref={drag}
            className="ml-auto"
            size="small"
            type="text"
            icon={<HolderOutlined />}
            onClick={() => {}}
          />
          <Tooltip title="delete">
            <Button
              className="ml-4"
              size="small"
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => {
                remove(item.index);
              }}
            />
          </Tooltip>
        </div>
      }
      className={classNames('site-collapse-custom-panel', classes.item)}
    >
      <div>{item.content}</div>
    </Panel>
  );
}
