import { SettingOutlined } from '@ant-design/icons';
import {
  Button,
  CreateButton,
  DateField,
  EditButton,
  getDefaultSortOrder,
  List,
  ShowButton,
  Space,
  Table,
  Tag,
  TextField,
  useTable,
} from '@pankod/refine-antd';
import { Link } from '@pankod/refine-react-router-v6';
import type { Project } from '@tokenbricks/talfa-backend-typescript-axios-client';
import { ProjectAoStatusEnum } from '@tokenbricks/talfa-backend-typescript-axios-client';
import { ethers } from 'ethers';

import { numberFormat } from '../../utils/numberFormat';

export const ProjectList: React.FC = () => {
  const { tableProps, sorter } = useTable({
    dataProviderName: 'crud',
    resource: 'projects',
    initialSorter: [
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
  });

  return (
    <List createButtonProps={CreateButton}>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="price" title="Price" />
        <Table.Column<Project>
          dataIndex={['securityToken', 'tokenSale', 'totalSoldQuantity']}
          title="Total Sold Quantity"
          render={(value, record) => (
            <TextField
              value={numberFormat(
                Number(
                  ethers.utils.formatUnits(
                    value,
                    record?.securityToken?.decimals
                  )
                )
              )}
            />
          )}
        />
        <Table.Column<Project>
          dataIndex={['securityToken', 'tokenSale', 'totalSaleSupply']}
          title="Total Sale Supply"
          render={(value, record) => (
            <TextField
              value={numberFormat(
                Number(
                  ethers.utils.formatUnits(
                    value,
                    record?.securityToken?.decimals
                  )
                )
              )}
            />
          )}
        />
        <Table.Column
          dataIndex={['securityToken', 'tokenSale', 'saleStartedAt']}
          title="Sale Started At"
          render={(value) => <DateField value={value} format="LLL" />}
        />
        <Table.Column
          dataIndex={['securityToken', 'tokenSale', 'saleEndedAt']}
          title="Sale Ended At"
          render={(value) => <DateField value={value} format="LLL" />}
        />
        <Table.Column
          dataIndex={'status'}
          title="Status"
          render={(value) => (
            <Tag
              color={value === ProjectAoStatusEnum.PUBLISHED ? 'green' : 'blue'}
            >
              {value}
            </Tag>
          )}
        />
        <Table.Column
          dataIndex={['createdAt']}
          title="Created At"
          sorter
          defaultSortOrder={getDefaultSortOrder('createdAt', sorter)}
          render={(value) => <DateField value={value} format="LLL" />}
        />
        <Table.Column<Project>
          title="Actions"
          dataIndex="id"
          render={(value) => (
            <Space>
              <EditButton hideText size="small" recordItemId={value} />
              <ShowButton hideText size="small" recordItemId={value} />
              <Link to={`/projects/setting/${value}`}>
                <Button size="small" icon={<SettingOutlined />} />
              </Link>
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
