// import { adminAuthenticationApi } from 'api/coreBackend/defHttp';
import axios from 'axios';

const DEBUG = process.env.NODE_ENV === 'development';

// TODO:The initialized action needs to be executed in _app.ts
export const createCoreAxios = () => {
  const instance = axios.create();

  instance.interceptors.request.use(
    (config) => {
      /** In dev, intercepts request and logs it into console for dev */
      if (DEBUG) {
        // console.info('✅', config);
      }
      return config;
    },
    (error) => {
      if (DEBUG) {
        // eslint-disable-next-line
        console.error("❌", error);
      }
      // TODO: global handle
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      if (response.status === 401) {
        // eslint-disable-next-line
        console.error("❌", "You are not authorized");
      }
      return response;
    },
    async (error) => {
      // const originalRequest = error.config;
      // eslint-disable-next-line no-underscore-dangle
      // if (error.response.status === 401 && !originalRequest._retry) {
      //   // eslint-disable-next-line no-underscore-dangle
      //   originalRequest._retry = true;
      //   const refreshToken = localStorage.getItem('refreshToken');
      //   instance.defaults.headers.common.Authorization = `Bearer ${refreshToken}`;
      //   const { data } = await adminAuthenticationApi.adminRefreshToken();
      //   instance.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
      //   return instance(originalRequest);
      // }
      if (error.response && error.response.data) {
        // TODO: global handle
        return Promise.reject(error.response.data);
      }
      // TODO: global handle
      return Promise.reject(error.message);
    }
  );

  return instance;
};
