export const getWalletImage = (walletId?: string) => {
  switch (walletId) {
    case 'metaMask':
      return '/images/wallets/metamask-logo.svg';
    case 'walletConnect':
      return '/images/wallets/walletconnect-logo.svg';
    case 'coinbaseWallet':
      return '/images/wallets/coinbase-wallet-logo.svg';
    case 'gnosis-safe':
      return '/images/wallets/gnosis-safe-logo.svg';
    default:
      return '/images/wallets/metamask-logo.svg';
  }
};
