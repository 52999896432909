import {
  BlockOutlined,
  FileImageOutlined,
  FileTextOutlined,
  ProjectOutlined,
  SettingOutlined,
  ShoppingOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { ErrorComponent, ReadyPage } from '@pankod/refine-antd';
import { QueryClient, QueryClientProvider, Refine } from '@pankod/refine-core';
import { RefineKbarProvider } from '@pankod/refine-kbar';
import nestjsCrudDataProvider from '@pankod/refine-nestjsx-crud';
import routerProvider from '@pankod/refine-react-router-v6';
import dataProvider from '@pankod/refine-simple-rest';
import { DataProvider as strapiV4DataProvider } from '@pankod/refine-strapi-v4';
import type { UpdateProjectStatusDto } from '@tokenbricks/talfa-backend-typescript-axios-client';
import {
  adminApplicationManagementAdminApi,
  coreBackendDefHttp,
  documentManagementAdminApi,
  paymentProviderSettingAdminApi,
  projectManagementAdminApi,
} from 'api/coreBackend/defHttp';
import { authProvider } from 'authProvider';
import { AutoConnectGnosisSafe } from 'components/AutoConnectGnosisSafe';
import { Header } from 'components/layout';
import { CustomLayout } from 'components/layout/layout';
import { OffLayoutArea } from 'components/OffLayoutArea';
import SwitchStoreSelect from 'components/SwitchStoreSelect';
import DynamicChainProvider from 'containers/DynamicChainProvider';
import { CreatePassword } from 'pages/auth/create-password';
import { Login } from 'pages/auth/login';
import { Register } from 'pages/auth/register';
import { SetNameSuccess } from 'pages/auth/success';
import { PageEdit } from 'pages/contents';
import { MemberList, MemberShow } from 'pages/members';
import {
  ProjectCreate,
  ProjectEdit,
  ProjectList,
  ProjectShow,
} from 'pages/projects';
import { ProjectSetting } from 'pages/projects/setting/[id]';
import { SettingDocumentList } from 'pages/settings/documents';
import { SettingPaymentList } from 'pages/settings/payments';
import { SponsorList } from 'pages/sponsors';
import { SponsorCreate } from 'pages/sponsors/ceate';
import { SponsorShow } from 'pages/sponsors/show';
import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { customize } from 'utils/custom-data-provider';
import { customNotificationProvider } from 'utils/customNotificationProvider';

import { Sider } from './components/layout';
import { PageList } from './pages/contents';
import { DocumentList } from './pages/documents';
import { OrderList, OrderShow } from './pages/orders';
import {
  ChainCreate,
  ChainEdit,
  ChainList,
  WalletCreate,
  WalletList,
} from './pages/settings';

const { REACT_APP_BASE_API_URL } = process.env;

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const [queryClient] = useState(
    () =>
      // https://stackoverflow.com/questions/72060239/react-query-nextjs-using-hydration-config-for-ssr-doesnt-use-cached-results-a
      new QueryClient()
  );

  return (
    <QueryClientProvider client={queryClient}>
      <DynamicChainProvider>
        <DndProvider backend={HTML5Backend}>
          <RefineKbarProvider>
            <Refine
              notificationProvider={customNotificationProvider}
              Layout={CustomLayout}
              Sider={Sider}
              ReadyPage={ReadyPage}
              catchAll={<ErrorComponent />}
              routerProvider={{
                ...routerProvider,
                routes: [
                  {
                    element: <ProjectSetting />,
                    path: '/projects/setting/:id',
                    layout: true,
                  },
                  {
                    element: <Register />,
                    path: '/auth/register',
                  },
                  {
                    element: <CreatePassword />,
                    path: '/auth/create-password',
                  },
                  {
                    element: <SetNameSuccess />,
                    path: '/auth/set-name/success',
                  },
                ],
              }}
              authProvider={authProvider}
              dataProvider={{
                default: dataProvider(
                  REACT_APP_BASE_API_URL,
                  coreBackendDefHttp
                ),
                strapiV4ContentManager: customize(
                  dataProvider(
                    `${REACT_APP_BASE_API_URL}/v1/admin/cms/content-manager`,
                    coreBackendDefHttp
                  ),
                  {
                    'content-types': {
                      getList: async ({ resource, filters }) => {
                        const { data } = await coreBackendDefHttp.get(
                          `${REACT_APP_BASE_API_URL}/v1/admin/cms/content-manager/${resource}`
                        );
                        if (!data || !data.data) {
                          return {
                            data: [],
                            total: 0,
                          };
                        }
                        const kindEqFilter = filters?.find(
                          (filter) =>
                            filter.operator === 'eq' && filter.field === 'kind'
                        );
                        const contentTypes = kindEqFilter
                          ? data.data.filter(
                              (ct: any) => ct.kind === kindEqFilter.value
                            )
                          : data.data;
                        return {
                          data: contentTypes,
                          total: contentTypes.length,
                        };
                      },
                      getOne: async ({ resource, id }) => {
                        const { data } = await coreBackendDefHttp.get(
                          `${REACT_APP_BASE_API_URL}/v1/admin/cms/content-manager/${resource}`
                        );
                        if (!data || !data.data) {
                          return { data: undefined };
                        }
                        const one = data.data.find((ct: any) => ct.uid === id);
                        return { data: one };
                      },
                    },
                    'single-types': {
                      update: async ({ resource, id, variables }) => {
                        const url = `${REACT_APP_BASE_API_URL}/v1/admin/cms/content-manager/${resource}/${id}`;
                        const { data } = await coreBackendDefHttp.put(
                          url,
                          variables
                        );

                        return {
                          data,
                        };
                      },
                    },
                    'content-types/configuration': {
                      getOne: async ({ resource, id }) => {
                        const [resourcePath, configSlug] = resource.split('/');
                        const url = `${REACT_APP_BASE_API_URL}/v1/admin/cms/content-manager/${resourcePath}/${id}/${configSlug}`;
                        const { data } = await coreBackendDefHttp.get(url);
                        return {
                          data,
                        };
                      },
                    },
                    components: {
                      getMany: async ({ ids }) => {
                        const url = `${REACT_APP_BASE_API_URL}/v1/admin/cms/content-manager/components`;
                        const { data } = await coreBackendDefHttp.get(url);
                        const components = data.data.filter(
                          (component: { uid: string }) =>
                            ids.includes(component.uid)
                        );
                        return {
                          data: components,
                        };
                      },
                    },
                  }
                ),
                strapiV4: customize(
                  strapiV4DataProvider(
                    `${REACT_APP_BASE_API_URL}/v1/admin/cms`,
                    coreBackendDefHttp
                  )
                ),
                customAPI: customize(
                  {},
                  {
                    'setting-documents': {
                      getList: async () => {
                        const { data } =
                          (await documentManagementAdminApi.getManyBaseAdminDocumentSettingControllerZohoSignSetting()) as any;
                        return {
                          data,
                          total: data.length,
                        };
                      },
                    },
                    'setting-payments': {
                      getList: async () => {
                        const { data } =
                          (await paymentProviderSettingAdminApi.getPaymentProviders()) as any;
                        return {
                          data,
                          total: data.length,
                        };
                      },
                    },
                    shareholders: {
                      getList: async (params) => {
                        const securityTokenIdEqFilter = params.filters?.find(
                          (filter) =>
                            filter.operator === 'eq' &&
                            filter.field === 'securityTokenId'
                        );

                        if (!securityTokenIdEqFilter) {
                          return {
                            data: [],
                            total: 0,
                          };
                        }

                        const { data } =
                          await projectManagementAdminApi.adminGetSecurityTokenShareholders(
                            securityTokenIdEqFilter.value,
                            params.pagination?.pageSize,
                            params.pagination?.current
                          );

                        return {
                          data: data.data,
                          total: data.pageCount,
                        };
                      },
                    },
                    'document-templates': {
                      getList: async () => {
                        const { data } =
                          (await documentManagementAdminApi.getDocumentTemplates()) as any;
                        return {
                          data: data.templates,
                          total: data.templates.length,
                        };
                      },
                    },
                    sponsors: {
                      getOne: async ({ id }) => {
                        const { data } =
                          (await adminApplicationManagementAdminApi.getOneBaseAdminApplicationControllerAdminApplication(
                            Number(id)
                          )) as any;
                        return {
                          data,
                        };
                      },
                      getList: async () => {
                        const { data } =
                          (await adminApplicationManagementAdminApi.getManyBaseAdminApplicationControllerAdminApplication()) as any;
                        return {
                          data,
                          total: data.length,
                        };
                      },
                      create: async ({ variables }) => {
                        const { data } =
                          (await adminApplicationManagementAdminApi.createAdminApplication(
                            {
                              email: (variables as { email: string }).email,
                              callbackPath: '/auth/register',
                            }
                          )) as any;
                        return {
                          data,
                        };
                      },
                    },
                  }
                ),
                crud: customize(
                  nestjsCrudDataProvider(
                    `${REACT_APP_BASE_API_URL}/v1/admin`,
                    coreBackendDefHttp
                  ),
                  {
                    'project-status': {
                      async update({ id, variables }) {
                        await projectManagementAdminApi.updateProjectStatus(
                          Number(id),
                          variables as UpdateProjectStatusDto
                        );
                        return {
                          data: {},
                        };
                      },
                    },
                  }
                ),
              }}
              LoginPage={Login}
              i18nProvider={i18nProvider}
              Header={Header}
              resources={[
                {
                  name: 'members',
                  icon: <UserOutlined />,
                  options: { dataProviderName: 'crud' },
                  list: MemberList,
                  show: MemberShow,
                },
                {
                  name: 'projects',
                  icon: <ProjectOutlined />,
                  options: { dataProviderName: 'crud' },
                  list: ProjectList,
                  create: ProjectCreate,
                  edit: ProjectEdit,
                  show: ProjectShow,
                },
                {
                  parentName: 'projects',
                  name: 'distributions',
                },
                // {
                //   parentName: 'projects',
                //   name: 'dividends',
                //   create: () => <>create</>,
                //   list: () => <>list</>,
                //   show: () => <>show</>,
                // },
                {
                  name: 'orders',
                  icon: <ShoppingOutlined />,
                  options: { dataProviderName: 'crud' },
                  list: OrderList,
                  show: OrderShow,
                },
                {
                  name: 'Document',
                  icon: <FileTextOutlined />,
                  options: { dataProviderName: 'crud' },
                  list: DocumentList,
                },
                {
                  name: 'content',
                  icon: <FileImageOutlined />,
                  // list: ContentManager,
                },
                {
                  name: 'single-types',
                  parentName: 'content',
                  icon: <FileTextOutlined />,
                  list: PageList,
                  edit: PageEdit,
                  options: {
                    label: 'Pages',
                    dataProviderName: 'strapiV4ContentManager',
                  },
                },
                {
                  name: 'settings',
                  icon: <SettingOutlined />,
                },
                {
                  name: 'chains',
                  parentName: 'settings',
                  icon: <BlockOutlined />,
                  options: { dataProviderName: 'crud' },
                  list: ChainList,
                  create: ChainCreate,
                  edit: ChainEdit,
                },
                {
                  name: 'wallets',
                  parentName: 'settings',
                  icon: <WalletOutlined />,
                  options: { dataProviderName: 'crud' },
                  list: WalletList,
                  create: WalletCreate,
                },

                {
                  name: 'documents',
                  parentName: 'settings',
                  icon: <FileTextOutlined />,
                  list: SettingDocumentList,
                },
                {
                  name: 'payments',
                  parentName: 'settings',
                  icon: <FileTextOutlined />,
                  list: SettingPaymentList,
                },
                {
                  name: 'sponsors',
                  icon: <UsergroupAddOutlined />,
                  options: { dataProviderName: 'customAPI' },
                  list: SponsorList,
                  create: SponsorCreate,
                  show: SponsorShow,
                },
              ]}
              Title={({ collapsed }) => (
                <div
                  className="px-4 pt-4 pb-2"
                  style={{
                    borderInlineEnd: '1px solid rgba(5, 5, 5, 0.06)',
                  }}
                >
                  {!collapsed && <SwitchStoreSelect />}
                </div>
              )}
              OffLayoutArea={OffLayoutArea}
            />
          </RefineKbarProvider>
          <AutoConnectGnosisSafe />
        </DndProvider>
      </DynamicChainProvider>
    </QueryClientProvider>
  );
}

export default App;
