import { Show } from '@pankod/refine-antd';
import type { IResourceComponentsProps } from '@pankod/refine-core';
import { useShow } from '@pankod/refine-core';
import type { Order } from '@tokenbricks/talfa-backend-typescript-axios-client';

export const OrderShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<Order>();

  return (
    <Show
      isLoading={queryResult.isLoading}
      title="Order Detail"
      contentProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    ></Show>
  );
};
