import {
  CreateButton,
  List,
  ShowButton,
  Space,
  Table,
  useTable,
} from '@pankod/refine-antd';
import type { HttpError, IResourceComponentsProps } from '@pankod/refine-core';
import type { Order } from '@tokenbricks/talfa-backend-typescript-axios-client';
import type { Dayjs } from 'dayjs';

export const SponsorList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<
    Order,
    HttpError,
    {
      orderCode: string;
      paymentType: string[];
      status: string[];
      createdAt: [Dayjs, Dayjs];
    }
  >();
  return (
    <List createButtonProps={CreateButton}>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="email" title="Email" />
        <Table.Column dataIndex="createdAt" title="Created At" />
        <Table.Column dataIndex="updatedAt" title="Updated At" />
        <Table.Column dataIndex="status" title="Status" />
        <Table.Column
          title="Actions"
          dataIndex="id"
          render={(value) => (
            <Space>
              <ShowButton hideText size="small" recordItemId={value} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
