import {
  CopyOutlined,
  DisconnectOutlined,
  DownOutlined,
  WifiOutlined,
} from '@ant-design/icons';
import type { MenuProps } from '@pankod/refine-antd';
import {
  Button,
  Col,
  Dropdown,
  Row,
  Select,
  Tag,
  Tooltip,
  Typography,
} from '@pankod/refine-antd';
import { useState } from 'react';
import {
  useAccount,
  useBalance,
  useConnect,
  useDisconnect,
  useNetwork,
  useSwitchNetwork,
} from 'wagmi';

import type { GnosisSafeConnector } from '../../connectors/gnosis-safe';
import { shortenAddress } from '../../utils/shortenAddress';
import { GnosisSafeModal } from '../GnosisSafeModal';
import { getChainImage } from './utils/getChainImage';
import { getWalletImage } from './utils/getWalletImage';

export const ConnectWallet: React.FC<any> = () => {
  const [isGnosisSafeModalOpen, setIsGnosisSafeModalOpe] =
    useState<boolean>(false);
  const openGnosisSafeModal = () => setIsGnosisSafeModalOpe(true);
  const closeGnosisSafeModal = () => setIsGnosisSafeModalOpe(false);
  const {
    address,
    isConnected: isWalletConnected,
    connector: activeConnector,
  } = useAccount();
  const { data: balance } = useBalance({ address, formatUnits: 'ether' });
  const { chain, chains } = useNetwork();

  const { switchNetworkAsync } = useSwitchNetwork();
  const { disconnectAsync } = useDisconnect();
  const { connectors, connectAsync } = useConnect();
  const [open, setOpen] = useState(false);
  const isGnosisSafeConnectorConnected =
    activeConnector?.id === 'gnosis-safe' && isWalletConnected;
  const handleConnect = async (connectorId: string) => {
    const connector = connectors.find((c) => c.id === connectorId);
    await connectAsync({ connector });
  };
  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };
  const handleSwitchToPersonalWallet = async () => {
    const gnosisSafeConnector = connectors.find(
      (c) => c.id === 'gnosis-safe'
    ) as GnosisSafeConnector;
    const { previousConnectorId } = gnosisSafeConnector;
    await disconnectAsync();

    const previousConnector = connectors.find(
      (c) => c.id === previousConnectorId
    );
    if (!previousConnector) {
      return;
    }
    await connectAsync({ connector: previousConnector });
  };
  const walletMenuItems: MenuProps['items'] = [
    {
      key: 'metaMask',
      label: 'MetaMask',
      icon: <img className="w-4 h-4" src="/images/wallets/metamask-logo.svg" />,
      onClick: async (e) => {
        await handleConnect(e.key);
      },
    },
    {
      key: 'walletConnect',
      label: 'WalletConnect',
      icon: (
        <img className="w-4 h-4" src="/images/wallets/walletconnect-logo.svg" />
      ),
      onClick: async (e) => {
        await handleConnect(e.key);
      },
    },
    {
      key: 'coinbaseWallet',
      label: 'Coinbase Wallet',
      icon: (
        <img
          className="w-4 h-4"
          src="/images/wallets/coinbase-wallet-logo.svg"
        />
      ),
      onClick: async (e) => {
        await handleConnect(e.key);
      },
    },
    {
      type: 'divider',
    },
    {
      key: 'gnosis-safe',
      label: (
        <>
          <Tooltip
            placement="bottom"
            title={
              'You need to first connect a personal wallet to connect to a Gnosis Safe.'
            }
          >
            Gnosis Safe
          </Tooltip>
        </>
      ),
      icon: (
        <img className="w-4 h-4" src="/images/wallets/gnosis-safe-logo.svg" />
      ),
      onClick: async (e) => {
        await handleConnect(e.key);
      },
      disabled: true,
    },
  ];
  const personalWalletConnectedMenuItems: MenuProps['items'] = [
    {
      label: (
        <>
          <Typography.Text strong>Personal Wallet</Typography.Text>
          <Tag className="ml-2" color="green">
            CONNECTED
          </Tag>
        </>
      ),
      type: 'group',
    },
    {
      key: 'copy-wallet-address',
      label: (
        <Typography.Text copyable={{ text: address }}>
          Copy Wallet Address
        </Typography.Text>
      ),
      icon: <CopyOutlined />,
    },
    {
      key: 'switch-network',
      label: (
        <>
          <Select
            className="w-full"
            size={'small'}
            defaultValue={chain?.name}
            options={chains.map((c) => ({ value: c.id, label: c.name }))}
            onSelect={async (value) => {
              if (typeof value === 'number' && switchNetworkAsync) {
                await switchNetworkAsync(value);
              }
            }}
          />
        </>
      ),
      icon: <WifiOutlined />,
    },
    {
      key: 'disconnect',
      label: 'Disconnect',
      onClick: async () => disconnectAsync(),
      icon: <DisconnectOutlined />,
    },
    {
      type: 'divider',
    },
    {
      label: (
        <>
          <Typography.Text strong>Team Wallet</Typography.Text>
        </>
      ),
      type: 'group',
    },
    {
      key: 'gnosis-safe',
      label: 'Connect Gnosis Safe',
      onClick: () => openGnosisSafeModal(),
      icon: <img src="/images/wallets/gnosis-safe-logo.svg" alt="" />,
    },
  ];
  const teamWalletConnectedMenuItems: MenuProps['items'] = [
    {
      label: (
        <>
          <Typography.Text strong>Personal Wallet</Typography.Text>
        </>
      ),
      type: 'group',
    },
    {
      key: 'switch-network',
      label: 'Switch to personal wallet',
      onClick: async () => handleSwitchToPersonalWallet(),
    },
    {
      type: 'divider',
    },
    {
      label: (
        <>
          <Typography.Text strong>Team Wallet</Typography.Text>
          <Tag className="ml-2" color="green">
            CONNECTED
          </Tag>
        </>
      ),
      type: 'group',
    },
    {
      key: 'copy-wallet-address',
      label: (
        <Typography.Text copyable={{ text: address }}>
          Copy Wallet Address
        </Typography.Text>
      ),
    },
    {
      key: 'disconnect',
      label: 'Disconnect',
      onClick: async () => disconnectAsync(),
    },
  ];

  if (address && isWalletConnected) {
    return (
      <>
        <GnosisSafeModal
          isOpen={isGnosisSafeModalOpen && !isGnosisSafeConnectorConnected}
          onClose={closeGnosisSafeModal}
        />
        <Dropdown
          menu={{
            items: isGnosisSafeConnectorConnected
              ? teamWalletConnectedMenuItems
              : personalWalletConnectedMenuItems,
          }}
          trigger={['click']}
          onOpenChange={handleOpenChange}
          open={open}
        >
          <Button style={{ height: 'auto' }}>
            {chain && chain.unsupported ? (
              <>Not Supported Network</>
            ) : (
              <Row gutter={[8, 8]} wrap={false} align="middle">
                <Col flex={1}>
                  <img
                    className="w-5 h-5 object-contain"
                    src={getChainImage(chain?.id)}
                    alt={getChainImage(chain?.id)}
                  />
                </Col>
                <Col flex={7}>
                  <Row align="top">
                    <div className="text-xs font-bold leading-tight">
                      {balance?.formatted.slice(0, 6) || '0.000'}{' '}
                      {balance?.symbol}
                    </div>
                  </Row>
                  <Row align="bottom">
                    <div className="text-xs leading-tight">
                      {shortenAddress(address)} ({chain?.name})
                    </div>
                  </Row>
                </Col>
                <Col flex={2}>
                  <img
                    className="w-5 h-5 mr-2 ml-2"
                    src={getWalletImage(activeConnector?.id)}
                    alt=""
                  />

                  <DownOutlined />
                </Col>
              </Row>
            )}
          </Button>
        </Dropdown>
      </>
    );
  }
  return (
    <Dropdown menu={{ items: walletMenuItems }} trigger={['click']}>
      <Button type="primary">
        Connect Wallet
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};
