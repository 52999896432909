export const setRedirectionUrlSteps = [
  '1. Go to https://api-console.zoho.com/.',
  "2. Click on 'ADD CLIENT'.",
  "3. Select 'Server-based Applications' as the client type.",
  '4. Enter your desired client name.',
  "5. Enter your website URL in the 'Homepage URL' field.",
  "6. Enter the redirection URL in the 'Authorized Redirect URIs' field.",
  "7. Click 'Create' to complete the setup.",
];

export const setWebhookUrlSteps = [
  '1. Go to https://sign.zoho.com/zs#/apisettings.',
  "2. Click on 'Create webhook'.",
  "3. Enter your webhook URL in the 'Callback URL' field.",
  '4. Enter your desired client name.',
  "5. Check all the options under 'Send callback when documents are'.",
  "6. Click 'Save' to complete the setup.",
];
