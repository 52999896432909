import { SearchOutlined } from '@ant-design/icons';
import {
  AntdLayout,
  Button,
  Dropdown,
  Icons,
  Menu,
  Space,
} from '@pankod/refine-antd';
import {
  useGetLocale,
  // useSetLocale,
} from '@pankod/refine-core';
import { useKBar } from '@pankod/refine-kbar';
import { Input } from 'antd';
import SetupGuide from 'containers/SetupGuide';
import ViewStoreButton from 'containers/ViewStoreButton';

import { ConnectWallet } from '../../ConnectWallet';

const { DownOutlined } = Icons;
// const { Text } = Typography;

export const Header: React.FC = () => {
  const { query } = useKBar();
  // const { i18n } = useTranslation();
  const locale = useGetLocale();
  // const changeLanguage = useSetLocale();
  // const { data: user } = useGetIdentity();

  const currentLocale = locale();

  const menu = (
    <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
      <Menu.Item>English</Menu.Item>
      {/* {[...(i18n.languages || [])].sort().map((lang: string) => (
        <Menu.Item
          key={lang}
          onClick={() => changeLanguage(lang)}
          icon={
            <span style={{ marginRight: 8 }}>
              <Avatar size={16} src={`/images/flags/${lang}.svg`} />
            </span>
          }
        >
          {lang === 'en' ? 'English' : 'German'}
        </Menu.Item>
      ))} */}
    </Menu>
  );

  return (
    <AntdLayout.Header
      className="drop-shadow"
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0px 24px',
        height: '56px',
        backgroundColor: '#FFF',
        zIndex: 100,
      }}
    >
      <div className="mr-auto flex items-center gap-1">
        <img className="h-9" src="/images/common/logo.png" alt="logo" />
        <h1 className="mb-0 italic font-bold text-xl">tokenbricks</h1>
      </div>
      <div className="flex-1 mx-4 flex justify-center items-center">
        <Input
          className="max-w-lg"
          // size="large"
          placeholder="Search (command / ctrl + k)"
          prefix={<SearchOutlined />}
          onClick={() => {
            query.toggle();
          }}
        />
      </div>
      <ViewStoreButton className="mr-3" />
      <Space style={{ marginRight: '8px' }}>
        <SetupGuide />
      </Space>
      <Dropdown overlay={menu}>
        <Button type="link">
          <Space>
            {/* <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} /> */}
            {currentLocale === 'en' ? 'English' : 'German'}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      {/* <Space style={{ marginLeft: '8px' }}>
        {user?.name && (
          <Text ellipsis strong>
            {user.name}
          </Text>
        )}
        {user?.avatar && <Avatar src={user?.avatar} alt={user?.name} />}
      </Space> */}
      <ConnectWallet />
    </AntdLayout.Header>
  );
};
