import { normalizeChainId } from '@wagmi/core';
import type { Signer } from 'ethers';
import { ethers, utils } from 'ethers';
import type { Address, Chain, ConnectorData } from 'wagmi';
import { Connector } from 'wagmi';
import { persist } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';

const gnosisSafeStore = createStore(
  persist(
    () => ({
      safeAddress: undefined as string | undefined,
      safeChainId: undefined as number | undefined,
      previousConnectorId: undefined as string | undefined,
    }),
    {
      name: 'tb-gnosis-safe',
    }
  )
);

// exerpt from https://docs.gnosis-safe.io/backend/available-services
const CHAIN_ID_TO_GNOSIS_SERVER_URL = {
  // mainnet
  1: 'https://safe-transaction-mainnet.safe.global',
  // avalanche
  43114: 'https://safe-transaction-avalanche.safe.global',
  // polygon
  137: 'https://safe-transaction-polygon.safe.global',
  // goerli
  5: 'https://safe-transaction-goerli.safe.global',
  // bsc
  56: 'https://safe-transaction-bsc.safe.global',
  // optimism
  10: 'https://safe-transaction-optimism.safe.global',
} as const;

export interface GnosisConnectorArguments {
  safeAddress?: string;
  safeChainId?: number;
}
// type Address = `0x${string}`;
// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
const __IS_SERVER__ = typeof window === 'undefined';

export class GnosisSafeConnector extends Connector<any, any, any> {
  static supportedChains = Object.keys(CHAIN_ID_TO_GNOSIS_SERVER_URL);

  public supportedChains = GnosisSafeConnector.supportedChains;

  id = 'gnosis-safe';

  ready = __IS_SERVER__;

  name = 'Gnosis Safe';

  // config
  public previousConnector?: Connector<any>;

  public previousConnectorId?: string;

  private config: GnosisConnectorArguments = {
    safeAddress: undefined,
    safeChainId: undefined,
  };

  private safeSigner?: Signer;

  constructor(config: { chains?: Chain[] }) {
    const modifiedConfig = { ...config };
    // filter out any chains that gnosis doesnt support before passing to connector
    modifiedConfig.chains = config.chains?.filter(
      (c) => c.id in CHAIN_ID_TO_GNOSIS_SERVER_URL
    );
    super({ ...modifiedConfig, options: undefined });
    const { safeAddress, safeChainId, previousConnectorId } =
      gnosisSafeStore.getState();
    if (safeAddress && safeChainId && previousConnectorId) {
      this.config.safeAddress = safeAddress;
      this.config.safeChainId = safeChainId;
      this.previousConnectorId = previousConnectorId;
    }
    if (!__IS_SERVER__) {
      this.ready = true;
    }
  }

  async connect(): Promise<Required<ConnectorData<any>>> {
    this.safeSigner = await this.createSafeSigner();
    const account = await this.getAccount();
    const provider = await this.getProvider();
    const id = await this.getChainId();
    return {
      account,
      provider,
      chain: { id, unsupported: this.isChainUnsupported(id) },
    };
  }

  public isChainSupported(chainId: string | number) {
    const id = normalizeChainId(chainId);
    return !this.isChainUnsupported(id);
  }

  private async createSafeSigner() {
    const signer = await this.previousConnector?.getSigner();
    const safeAddress = this.config?.safeAddress;
    const safeChainId = this.config
      ?.safeChainId as keyof typeof CHAIN_ID_TO_GNOSIS_SERVER_URL;
    // invariant(
    //   signer,
    //   'cannot create Gnosis Safe signer without a personal signer'
    // );
    // const signerChainId = await signer.getChainId();
    // invariant(
    //   signerChainId === safeChainId,
    //   'chainId of personal signer has to match safe chainId'
    // );
    // invariant(
    //   safeAddress,
    //   'safeConfig.safeAddress is required, did you forget to call setSafeConfig?'
    // );
    // invariant(
    //   safeChainId,
    //   'safeConfig.safeChainId is required, did you forget to call setSafeConfig?'
    // );
    const serverUrl = CHAIN_ID_TO_GNOSIS_SERVER_URL[safeChainId];
    // invariant(serverUrl, 'Chain not supported');

    const [safeEthersAdapters, safeCoreSdk, safeEthersLib] = await Promise.all([
      import('@safe-global/safe-ethers-adapters'),
      import('@safe-global/safe-core-sdk'),
      import('@safe-global/safe-ethers-lib'),
    ]);

    // eslint-disable-next-line new-cap
    const ethAdapter = new safeEthersLib.default({
      ethers,
      signerOrProvider: signer,
    });

    const safe = await safeCoreSdk.default.create({
      ethAdapter: ethAdapter as any,
      safeAddress: safeAddress!,
    });
    const service = new safeEthersAdapters.SafeService(serverUrl);
    return new safeEthersAdapters.SafeEthersSigner(
      safe as any,
      service,
      signer.provider
    );
  }

  // eslint-disable-next-line class-methods-use-this
  async disconnect(): Promise<void> {
    gnosisSafeStore.setState({
      safeAddress: undefined,
      safeChainId: undefined,
      previousConnectorId: undefined,
    });
    this.config.safeAddress = undefined;
    this.config.safeChainId = undefined;
    this.safeSigner = undefined;
    this.previousConnector = undefined;
    this.previousConnectorId = undefined;
    return undefined;
  }

  async getAccount(): Promise<Address> {
    const signer = await this.getSigner();
    return (await signer.getAddress()) as Address;
  }

  async getChainId(): Promise<number> {
    return (await this.getSigner()).getChainId();
  }

  async getProvider() {
    return (await this.getSigner()).provider;
  }

  async getSigner(): Promise<Signer> {
    if (!this.safeSigner) {
      this.safeSigner = await this.createSafeSigner();
    }
    return this.safeSigner;
  }

  async isAuthorized(): Promise<boolean> {
    try {
      const account = await this.getAccount();
      return !!account;
    } catch {
      return false;
    }
  }

  protected onAccountsChanged(accounts: string[]) {
    if (accounts.length === 0) {
      this.emit('disconnect');
    } else {
      this.emit('change', { account: utils.getAddress(accounts[0]) });
    }
  }

  protected override isChainUnsupported(chainId: number) {
    return !(chainId === this.config.safeChainId);
  }

  protected onChainChanged(chainId: string | number) {
    const id = normalizeChainId(chainId);
    const unsupported = this.isChainUnsupported(id);
    this.emit('change', { chain: { id, unsupported } });
  }

  protected onDisconnect() {
    this.emit('disconnect');
  }

  public setConnector(connector: Connector<any>) {
    this.previousConnector = connector;
  }

  public getConfiguration(): GnosisConnectorArguments | undefined {
    return this.config;
  }

  public setConfiguration(
    connector: Connector<any>,
    config: GnosisConnectorArguments
  ) {
    gnosisSafeStore.setState({
      safeAddress: config.safeAddress,
      safeChainId: config.safeChainId,
      previousConnectorId: connector.id,
    });

    this.previousConnector = connector;
    this.previousConnectorId = connector.id;
    this.config = config;
  }
}
