import {
  Button,
  Create,
  Form,
  SaveButton,
  Steps,
  useStepsForm,
} from '@pankod/refine-antd';
import { useMany, useOne } from '@pankod/refine-core';
import type { AdminProjectDetailAo } from '@tokenbricks/talfa-backend-typescript-axios-client';
import { useEffect } from 'react';
import { mediaUploadMapper } from 'views/content/utils/mediaUploadMapper';

import type { ContentTypeData } from '../../interfaces/contentType';
import type { ContentTypesConfiguration } from '../../interfaces/strapi';
import { useGetFormList } from '../../views/projects/hooks';
// import type { Project } from '@tokenbricks/talfa-backend-typescript-axios-client';
const { Step } = Steps;

export const ProjectCreate: React.FC = () => {
  const {
    current,
    gotoStep,
    stepsProps,
    formProps,
    saveButtonProps,
    form,
    onFinish,
  } = useStepsForm<AdminProjectDetailAo>();

  const content = {};

  const { data: contentTypeData } = useOne<ContentTypeData>({
    dataProviderName: 'strapiV4ContentManager',
    resource: 'content-types',
    id: `api::project.project`,
  });

  const { data, isLoading: isConfigLoading } =
    useOne<ContentTypesConfiguration>({
      dataProviderName: 'strapiV4ContentManager',
      resource: 'content-types/configuration',
      id: `api::project.project`,
    });
  const contentType = contentTypeData?.data;
  const config = data?.data?.data;

  const componentIds = Object.keys(config?.components ?? {});

  const { data: components } = useMany({
    dataProviderName: 'strapiV4ContentManager',
    resource: 'components',
    ids: componentIds,
  });

  const formList = useGetFormList({
    contentType,
    componentIds,
    content,
    form,
    formProps,
    components,
    config,
  });

  useEffect(() => {
    if (
      // Token step
      current === 2 &&
      !form.getFieldValue(['project', 'type'])
    ) {
      const projectName = form.getFieldValue(['content', 'name']);
      form.setFieldsValue({
        project: {
          type: 'EQUITY',
          minimumPurchaseAmount: 1,
          securityToken: {
            name: projectName,
            symbol: projectName,
            decimals: 18,
            standard: 'erc20',
            tokenSale: {
              initialOfferingLockSecond: 1,
            },
          },
        },
      });
    }
  }, [current]);

  return (
    <Create
      isLoading={isConfigLoading}
      footerButtons={
        <>
          {current > 0 && (
            <Button
              onClick={() => {
                gotoStep(current - 1);
              }}
            >
              Previous
            </Button>
          )}
          {current < formList.length - 1 && (
            <Button
              onClick={() => {
                gotoStep(current + 1);
              }}
            >
              Next
            </Button>
          )}
          {current === formList.length - 1 && (
            <SaveButton
              {...saveButtonProps}
              onClick={() => {
                // https://github.com/refinedev/refine/issues/1594#issuecomment-1054435801
                const values = form.getFieldsValue(true);
                if (values?.project?.securityToken?.chainId) {
                  values.project.securityToken.tokenSale.chainId =
                    values.project.securityToken.chainId;
                }
                if (values?.project?.securityToken?.tokenSale?.saleBetween) {
                  values.project.securityToken.tokenSale.saleStartedAt =
                    values?.project?.securityToken?.tokenSale?.saleBetween[0];
                  values.project.securityToken.tokenSale.saleEndedAt =
                    values?.project?.securityToken?.tokenSale?.saleBetween[1];
                }
                if (values?.content?.urlHandle) {
                  values.project.handle = values.content.urlHandle;
                }
                if (values?.content?.name) {
                  values.project.name = values?.content?.name;
                }

                if (values?.content?.summary) {
                  values.project.summary = values.content.summary;
                }

                if (values?.content?.previewImage?.file?.response[0]?.url) {
                  values.project.previewImageUrl =
                    values?.content?.previewImage?.file?.response[0]?.url;
                }
                onFinish(mediaUploadMapper(values));
              }}
            />
          )}
        </>
      }
    >
      <Steps {...stepsProps}>
        <Step title="Content" />
        <Step title="Sale" />
        <Step title="Token" />
      </Steps>
      <Form {...formProps} layout="vertical" style={{ marginTop: 30 }}>
        {/*  TODO Payment Token */}
        {/* TODO Payment Methods */}
        {formList[current]}
      </Form>
    </Create>
  );
};
