export function GrayCircleIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <circle
        cx="10"
        cy="10"
        r="8.75"
        stroke="#BABEC3"
        strokeWidth="2.5"
        strokeDasharray="3.5 3.5"
      ></circle>
    </svg>
  );
}
