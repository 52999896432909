import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { useCallback } from 'react';
import { useDrop } from 'react-dnd';

import ItemDnd from '../ItemDnd';

interface Props {
  items: {
    id: number | string;
    index: number;
    name: string;
    content: React.ReactNode;
  }[];
  add?: (defaultValue?: any, insertIndex?: number | undefined) => void;
  move: (from: number, to: number) => void;
  remove: (index: number | number[]) => void;
}

export default function CollapseMenuDnd({ items, add, move, remove }: Props) {
  const find = useCallback(
    (id: number | string) => {
      const item = items.filter((c) => c.id === id)[0];
      return {
        item,
        index: items.indexOf(item),
      };
    },
    [items]
  );

  const [, drop] = useDrop(() => ({ accept: 'box' }));

  return (
    <div ref={drop}>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            className="flex items-center"
            rotate={isActive ? 90 : 0}
          />
        )}
        className="site-collapse-custom-collapse"
      >
        {items.map((item) => {
          return (
            <ItemDnd
              find={find}
              key={item.id}
              move={move}
              item={item}
              remove={remove}
            />
          );
        })}
        {add && (
          <div
            className="w-full h-12 text-indigo-500 font-semibold cursor-pointer flex items-center justify-center hover:bg-slate-100 transition"
            onClick={() => {
              add?.({}, items.length);
            }}
          >
            + Add an entry
          </div>
        )}
      </Collapse>
    </div>
  );
}
