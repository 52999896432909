import { useQuery } from '@pankod/refine-core';
import { supportedChainManagementAdminApi } from 'api/coreBackend/defHttp';
import { GnosisSafeConnector } from 'connectors/gnosis-safe';
import { useMemo } from 'react';
import { getChainById } from 'utils/getChainById';
import type { Chain } from 'wagmi';
import { configureChains, createClient } from 'wagmi';
import { goerli, mainnet } from 'wagmi/chains';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

const defaultChains = [mainnet, goerli];

interface Props {
  children: (wagmiClient: any, chains: Chain[]) => React.ReactNode;
}

export default function SupportedChainProvider({ children }: Props) {
  const { data } = useQuery(
    ['supportedChainManagementAdminApi.getManyBaseAdminChainControllerChain'],
    () =>
      supportedChainManagementAdminApi.getManyBaseAdminChainControllerChain(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: Infinity,
    }
  );

  const config = useMemo(() => {
    let chainsData: Chain[] = defaultChains;
    const chainsProvider = [publicProvider()];

    const supportedChains = data?.data;

    // TODO: backend 回傳格式有兩種，之後可能需要統一
    if (supportedChains && !Array.isArray(supportedChains)) {
      throw Error('Error: chain type by getManyBaseAdminChainControllerChain');
    }

    const filterChains = supportedChains?.map((_data: any) =>
      getChainById(_data.chainId)
    );

    if (filterChains && filterChains.length > 0) {
      chainsData = filterChains;
    }

    supportedChains?.forEach((chain) => {
      if (chain.alchemyApiKey) {
        chainsProvider.unshift(
          alchemyProvider({ apiKey: chain.alchemyApiKey })
        );
      }
    });

    const { chains, provider } = configureChains(chainsData, chainsProvider);

    const client = createClient({
      // autoConnect: false,
      autoConnect: true,
      provider,
      connectors: [
        new MetaMaskConnector({ chains }),
        new CoinbaseWalletConnector({
          chains,
          options: {
            // TODO from API
            appName: 'Talfa',
          },
        }),
        new WalletConnectConnector({
          chains,
          options: {
            qrcode: true,
          },
        }),
        new InjectedConnector({
          chains,
          options: {
            name: 'Injected',
            shimDisconnect: true,
          },
        }),
        new GnosisSafeConnector({}),
      ],
    });

    return { client, chains };
  }, [data]);

  return <>{children(config?.client, config?.chains ?? [])}</>;
}
