import type { FormInstance } from '@pankod/refine-antd';
import { Card, Form, Input } from '@pankod/refine-antd';
import { getValueProps } from '@pankod/refine-strapi-v4';
import MDEditor from '@uiw/react-md-editor';
import { CMSEditDataType } from 'views/content/enum/cms';
import type { CMSEditData } from 'views/content/model/cms';

import CollapseMenuDnd from '../CollapseMenuDnd';
import DynamicAddButton from '../DynamicAddButton';
import UploadImage from '../UploadImage';

interface Props {
  keys: string[];
  data: CMSEditData;
  form: FormInstance<{}>;
}

export const CmsFormItem = ({ keys, data, form }: Props) => {
  switch (data.type) {
    case CMSEditDataType.String:
    case CMSEditDataType.Text:
      return (
        <>
          <Form.Item
            className="mb-0"
            label={data.label}
            name={keys}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <p className="text-xs mb-6 text-slate-700 mt-1">{data.description}</p>
        </>
      );
    case CMSEditDataType.Media:
      return (
        <>
          <Form.Item
            className="mb-0"
            valuePropName="fileList"
            getValueProps={(_data) => {
              let fileData = _data;
              if (
                fileData &&
                !fileData.file &&
                !fileData.fileList &&
                !Array.isArray(fileData)
              ) {
                fileData = [fileData];
              }
              return getValueProps(fileData, ``);
            }}
            label={data.label}
            name={keys}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <UploadImage multiple={data.repeatable} />
          </Form.Item>
          <p className="text-xs text-slate-700 mt-1">{data.description}</p>
        </>
      );
    case CMSEditDataType.Richtext:
      return (
        <>
          <Form.Item
            className="mb-0"
            label={data.label}
            name={keys}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <MDEditor data-color-mode="light" />
          </Form.Item>
          <p className="text-xs text-slate-700 mt-1">{data.description}</p>
        </>
      );
    case CMSEditDataType.Component:
      if (data.repeatable && Array.isArray(data.value)) {
        return (
          <Form.Item label={data.label}>
            <Form.List name={keys}>
              {(fields, { add, remove, move }) => {
                const items = fields.map((field, index) => {
                  const id = form.getFieldValue([...keys, field.name, 'id']);

                  const fieldName = form.getFieldValue([
                    ...keys,
                    field.name,
                    'name',
                  ]);
                  const fieldTitle = form.getFieldValue([
                    ...keys,
                    field.name,
                    'title',
                  ]);
                  const name = fieldName || fieldTitle || '';

                  return {
                    id,
                    index,
                    name,
                    content: (data.value as CMSEditData[]).map((_value) => {
                      return (
                        <CmsFormItem
                          key={_value.id}
                          keys={[String(field.name), String(_value.id)]}
                          data={_value}
                          form={form}
                        />
                      );
                    }),
                  };
                });
                return (
                  <CollapseMenuDnd
                    items={items}
                    add={add}
                    remove={remove}
                    move={move}
                  />
                );
              }}
            </Form.List>
          </Form.Item>
        );
      }
      return (
        <Form.Item label={data.label}>
          <Card>
            {(data.value as CMSEditData[]).map((_data) => {
              return (
                <CmsFormItem
                  key={_data.id}
                  keys={[...keys, String(_data.id)]}
                  data={_data}
                  form={form}
                />
              );
            })}
          </Card>
        </Form.Item>
      );
    case CMSEditDataType.Dynamiczone:
      return (
        <Form.Item label={data.label}>
          <Form.List name={keys}>
            {(fields, { add, remove, move }) => {
              const items = fields.map((field, index) => {
                const fieldName = form.getFieldValue([
                  ...keys,
                  field.name,
                  'name',
                ]);
                const id = form.getFieldValue([...keys, field.name, 'id']);
                const componentUid = form.getFieldValue([
                  ...keys,
                  field.name,
                  '__component',
                ]);
                const fieldTitle = form.getFieldValue([
                  ...keys,
                  field.name,
                  'title',
                ]);

                const componentIndex = data.components?.findIndex(
                  (c) => c.uid === componentUid
                );

                // @ts-ignore
                const componentValue = data.value?.[componentIndex ?? 0];

                return {
                  id: componentUid + id,
                  index,
                  name: `${
                    data.components?.find((c) => c.uid === componentUid)
                      ?.displayName ?? ''
                  } - ${fieldName || fieldTitle || 'text'}`,
                  content: (componentValue as CMSEditData[]).map((_value) => {
                    return (
                      <CmsFormItem
                        key={_value.id}
                        keys={[String(field.name), String(_value.id)]}
                        data={_value}
                        form={form}
                      />
                    );
                  }),
                };
              });
              return (
                <>
                  <CollapseMenuDnd items={items} remove={remove} move={move} />
                  {data.components && (
                    <DynamicAddButton
                      components={data.components}
                      onClick={(uid) => {
                        add({ __component: uid });
                      }}
                    />
                  )}
                </>
              );
            }}
          </Form.List>
        </Form.Item>
      );
    default:
      return <></>;
  }
};
