import type { AuthProvider } from '@pankod/refine-core';
import {
  adminApplicationManagementAdminApi,
  adminAuthenticationApi,
  coreBackendDefHttp,
} from 'api/coreBackend/defHttp';
import { refreshAuthToken } from 'utils/refreshAuthToken';

export const authProvider: AuthProvider = {
  register: async ({ data, token }) => {
    console.log(data, token);
    await adminApplicationManagementAdminApi.submitAdminApplication({
      data,
      token,
    });
    return '/auth/set-name/success';
  },
  login: async ({ username, password }) => {
    const res = await adminAuthenticationApi.adminLocalSignin({
      email: username,
      password,
    });
    if (res.data.accessToken) {
      localStorage.setItem('accessToken', res.data.accessToken);
      localStorage.setItem('refreshToken', res.data.refreshToken);

      // console.log('accessToken', res.data.accessToken);
      // set header axios instance
      coreBackendDefHttp.defaults.headers.common = {
        Authorization: `Bearer ${res.data.accessToken}`,
      };
      refreshAuthToken();

      return Promise.resolve();
    }
    return Promise.reject();
  },
  logout: () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    adminAuthenticationApi.adminLocalSignout();
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    // console.log('checkAuth');
    refreshAuthToken();
    const accessToken = localStorage.getItem('accessToken');
    // const refreshToken = localStorage.getItem('refreshToken');
    if (accessToken) {
      coreBackendDefHttp.defaults.headers.common = {
        Authorization: `Bearer ${accessToken}`,
      };
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const accessToken = localStorage.getItem('accessToken');
    // const refreshToken = localStorage.getItem('refreshToken');
    if (!accessToken) {
      return Promise.reject();
    }

    const { data, status } = await adminAuthenticationApi.adminGetMe();
    if (status === 200) {
      const { id, name, email } = data;
      return Promise.resolve({
        id,
        name,
        email,
      });
    }

    return Promise.reject();
  },
};
