import { SearchOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Col,
  DateField,
  DatePicker,
  Form,
  getDefaultSortOrder,
  Input,
  List,
  Row,
  Select,
  ShowButton,
  Space,
  Table,
  useTable,
} from '@pankod/refine-antd';
import type {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
} from '@pankod/refine-core';
import type { Member } from '@tokenbricks/talfa-backend-typescript-axios-client';
import type { Dayjs } from 'dayjs';

const { RangePicker } = DatePicker;

export const MemberList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter, searchFormProps } = useTable<
    Member,
    HttpError,
    {
      keyword: string;
      kycLevels: string[];
      emailVerifieds: boolean[];
      createdAt: [Dayjs, Dayjs];
    }
  >({
    initialSorter: [
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { keyword, createdAt, emailVerifieds, kycLevels } = params;
      filters.push(
        {
          operator: 'or',
          value: [
            {
              field: 'email',
              operator: 'contains',
              value: keyword || undefined,
            },
            {
              field: 'name',
              operator: 'contains',
              value: keyword || undefined,
            },
          ],
        },
        {
          operator: 'in',
          field: 'emailVerified',
          value:
            emailVerifieds && emailVerifieds.length > 0
              ? emailVerifieds
              : undefined,
        },
        {
          field: 'kycLevel',
          operator: 'in',
          value: kycLevels && kycLevels.length > 0 ? kycLevels : undefined,
        },
        {
          field: 'createdAt',
          operator: 'gte',
          value: createdAt ? createdAt[0].toISOString() : undefined,
        },
        {
          field: 'createdAt',
          operator: 'lte',
          value: createdAt ? createdAt[1].toISOString() : undefined,
        }
      );

      return filters;
    },
  });
  return (
    <List>
      <Row gutter={[16, 16]}>
        <Col lg={6} xs={24}>
          <Card title="Filters">
            <Form layout="vertical" {...searchFormProps}>
              <Form.Item label="Search" name="keyword">
                <Input
                  allowClear
                  placeholder="User Name or Email"
                  prefix={<SearchOutlined />}
                />
              </Form.Item>
              <Form.Item label="Email Verified" name="emailVerifieds">
                <Select
                  allowClear
                  mode="multiple"
                  options={[
                    {
                      label: 'Yes',
                      value: true,
                    },
                    {
                      label: 'No',
                      value: false,
                    },
                  ]}
                  placeholder="Email Verified"
                />
              </Form.Item>
              <Form.Item label="KYC Level" name="kycLevels">
                <Select
                  allowClear
                  mode="multiple"
                  options={[
                    // TODO from api
                    {
                      label: 'NON US INDIVIDUAL',
                      value: 'NON_US_INDIVIDUAL',
                    },
                    {
                      label: 'US ACCREDITED INDIVIDUAL',
                      value: 'US_ACCREDITED_INDIVIDUAL',
                    },
                    {
                      label: 'US NON ACCREDITED INDIVIDUAL',
                      value: 'US_NON_ACCREDITED_INDIVIDUAL',
                    },
                    {
                      label: 'NON US BUSINESS',
                      value: 'NON_US_BUSINESS',
                    },
                    {
                      label: 'US BUSINESS',
                      value: 'US_BUSINESS',
                    },
                    {
                      label: 'NO KYC',
                      value: 'NO_KYC',
                    },
                  ]}
                  placeholder="KYC Level"
                />
              </Form.Item>
              <Form.Item label="Register Date" name="createdAt">
                <RangePicker />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Filter
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col lg={18} xs={24}>
          <Table {...tableProps} rowKey="id">
            <Table.Column dataIndex="name" title="User Name" />
            <Table.Column<Member>
              dataIndex="email"
              title="Email"
              render={(value, record) => (
                <Badge
                  status={record.emailVerified ? 'success' : 'error'}
                  text={value}
                />
              )}
            />
            <Table.Column<Member>
              dataIndex="kycLevel"
              title="KYC Level"
              render={(value) => (
                <Badge
                  status={value !== 'NO_KYC' ? 'success' : 'error'}
                  text={value.replaceAll('_', ' ')}
                />
              )}
            />
            <Table.Column
              dataIndex="createdAt"
              title="Register Date"
              key="createdAt"
              sorter
              defaultSortOrder={getDefaultSortOrder('createdAt', sorter)}
              render={(value) => <DateField value={value} format="LLL" />}
            />
            <Table.Column<Member>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <ShowButton hideText size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </Col>
      </Row>
    </List>
  );
};
