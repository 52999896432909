import { TextField, Tooltip } from '@pankod/refine-antd';
import React from 'react';
import { shortenAddress } from 'utils/shortenAddress';

import { getChainById } from '../../utils/getChainById';

type TransactionLinkProps = {
  transactionHash: string;
  chainId: number;
  short?: boolean;
};
export const TransactionLink: React.FC<TransactionLinkProps> = ({
  transactionHash,
  chainId,
  short = true,
}) => {
  const explorerUrl = chainId
    ? getChainById(chainId)?.blockExplorers?.default.url
    : '';

  const addressUrl = `${explorerUrl}/tx/${transactionHash}`;

  return (
    <Tooltip title={transactionHash}>
      <a target={'_blank'} href={addressUrl}>
        <TextField
          copyable
          value={
            short ? shortenAddress(transactionHash, true) : transactionHash
          }
        />
      </a>
    </Tooltip>
  );
};
