// refine source code 有問題,從 refine source code 複製出來的,改成遞迴的寫法
export const mediaUploadMapper = (params: any) => {
  // eslint-disable-next-line array-callback-return
  Object.keys(params).map((item) => {
    if (params[item]) {
      const param = params[item].fileList;
      const isMediaField = Array.isArray(param);
      if (isMediaField) {
        const ids = [];
        // eslint-disable-next-line @typescript-eslint/no-shadow, no-restricted-syntax
        for (const item of param) {
          if (item.response) {
            // eslint-disable-next-line no-restricted-syntax
            for (const response of item.response) {
              ids.push(response.id);
            }
          } else {
            ids.push(item.uid);
          }
        }
        // eslint-disable-next-line no-param-reassign
        params[item] = ids;
      } else if (typeof params[item] === 'object') {
        mediaUploadMapper(params[item]);
      }
    }
  });
  return params;
};
