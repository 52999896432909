import { Button, Form, Input, Modal, Select } from '@pankod/refine-antd';
import { ethers } from 'ethers';
import {
  avalanche,
  bsc,
  goerli,
  mainnet,
  optimism,
  polygon,
} from 'wagmi/chains';

import { useGnosisSafe } from '../../hooks/useGnosisSafe';

export const GNOSIS_TO_CHAIN_ID = {
  // supported mainnets
  eth: mainnet.id,
  matic: polygon.id,
  avax: avalanche.id,
  bnb: bsc,
  oeth: optimism.id,
  // supported testnets
  gor: goerli.id,
} as const;
// export const CHAIN_ID_TO_GNOSIS = Object.entries(GNOSIS_TO_CHAIN_ID).reduce(
//   (acc, [gnosis, chainId]) => ({
//     ...acc,
//     [chainId]: gnosis,
//   }),
//   {} as Record<
//     (typeof GNOSIS_TO_CHAIN_ID)[keyof typeof GNOSIS_TO_CHAIN_ID],
//     keyof typeof GNOSIS_TO_CHAIN_ID
//   >
// );

interface ConnectorModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const GnosisSafeModal: React.FC<ConnectorModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [form] = Form.useForm();
  const connectGnosisSafe = useGnosisSafe();
  // const formErrors = form.getFieldsError().some(({ errors }) => errors.length);
  // console.log(form.isFieldsValidating());

  return (
    <Modal
      centered
      title="Gnosis Safe Connect"
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button
          block
          type="primary"
          size="large"
          htmlType="submit"
          onClick={async () => {
            try {
              await connectGnosisSafe({
                safeAddress: form.getFieldValue('safeAddress'),
                safeChainId: Number(form.getFieldValue('safeChainId')),
              });
              onClose();
            } catch (e) {
              console.error(e);
            }
          }}
          // disabled={form.getFieldsError().some(({ errors }) => errors.length)}
        >
          Connect to Gnosis Safe
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          safeAddress: '',
          safeChainId: (-1).toString(),
        }}
      >
        <Form.Item
          required
          key="safeAddress"
          name="safeAddress"
          label="Safe Address"
          rules={[
            { required: true, message: 'Please input your Safe Address!' },
            {
              validator: (_, value) => {
                if (!ethers.utils.getAddress(value)) {
                  throw new Error('Invalid address');
                }
              },
              message: 'Invalid address',
            },
          ]}
        >
          <Input
            onPaste={(e) => {
              try {
                const text = e.clipboardData.getData('Text');
                if (text.indexOf(':0x') > -1) {
                  // looks like we have a network key from gnosis safe in there

                  const [gnosisNetwork, gnosisSafeAddress] = text.split(':');

                  // prevent the default (setting the data to the input) since we're about to handle it
                  if (
                    ethers.utils.isAddress(gnosisSafeAddress) &&
                    gnosisNetwork in GNOSIS_TO_CHAIN_ID
                  ) {
                    e.preventDefault();
                    // just re-set the form with the data we found
                    form.setFieldsValue({
                      safeAddress: gnosisSafeAddress,
                      safeChainId:
                        GNOSIS_TO_CHAIN_ID[
                          gnosisNetwork as keyof typeof GNOSIS_TO_CHAIN_ID
                        ].toString(),
                    });
                  }
                }
              } catch (err) {
                // TODO handle error
                console.error('failed to get paste data', err);
              }
            }}
            size="large"
            placeholder={`net:${ethers.constants.AddressZero}`}
          />
        </Form.Item>
        <Form.Item
          required
          key="safeChainId"
          name="safeChainId"
          label="Safe Network"
        >
          <Select
            size="large"
            options={[
              { label: 'Ethereum Mainnet', value: mainnet.id.toString() },
              { label: 'Polygon Mainnet', value: polygon.id.toString() },
              { label: 'Avalanche Mainnet', value: avalanche.id.toString() },
              { label: 'Binance Smart Chain', value: bsc.toString() },
              { label: 'Optimism Mainnet', value: optimism.id.toString() },
              { label: 'Ethereum Goerli', value: goerli.id.toString() },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
