import { CheckOutlined } from '@ant-design/icons';
import { ProCard } from '@ant-design/pro-components';
import {
  Button,
  Col,
  CreateButton,
  DateField,
  EditButton,
  Form,
  getDefaultSortOrder,
  List,
  ListButton,
  Modal,
  Row,
  Show,
  ShowButton,
  Space,
  Steps,
  Table,
  TextField,
  useDrawerForm,
  useModal,
  useModalForm,
  useTable,
} from '@pankod/refine-antd';
import { useShow, useUpdate } from '@pankod/refine-core';
import type {
  AdminProjectDetailAo,
  SecurityTokenTransferEvent,
} from '@tokenbricks/talfa-backend-typescript-axios-client';
import { ProjectAoStatusEnum } from '@tokenbricks/talfa-backend-typescript-axios-client';
// import { Table } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { BigNumber, ethers } from 'ethers';
import RcResizeObserver from 'rc-resize-observer';
import { useState } from 'react';
import type { ProjectContent } from 'views/projects/model/project';
import { usePrepareSendTransaction, useSendTransaction } from 'wagmi';

import {
  CreateDistribution,
  DividendList,
  EditDistribution,
} from '../../components/Distribution';
import { AddressLink, TransactionLink } from '../../components/Links';
import { numberFormat } from '../../utils/numberFormat';

dayjs.extend(duration);
dayjs.extend(relativeTime);
export const ProjectShow: React.FC = () => {
  const [, setResponsive] = useState(false);
  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editModalShow,
  } = useModalForm<any>({
    action: 'edit',
    warnWhenUnsavedChanges: true,
  });
  const { config } = usePrepareSendTransaction({
    request: {
      to: '0x70Bf7b543650AeF03E50cA20B3B4221BaA63a849',
      value: BigNumber.from('100000000000000'),
    },
  });
  const { data, isLoading, isSuccess, sendTransaction } =
    useSendTransaction(config);

  const [pulishmentLoading, setPulishmentLoading] = useState(false);
  const { mutate: mutateProjectStatus } = useUpdate<{
    status: ProjectAoStatusEnum;
  }>();

  const [current, setCurrent] = useState(0);

  const { queryResult } = useShow<AdminProjectDetailAo>({
    dataProviderName: 'crud',
    resource: 'projects',
  });
  const project = queryResult.data?.data?.project;
  // @ts-ignore
  const projectDocument = queryResult.data?.data?.project.projectDocument;
  const projectDocumentTemplate =
    queryResult.data?.data?.projectDocumentTemplate;
  const { tableProps: shareholderTableProps } = useTable({
    dataProviderName: 'customAPI',
    resource: 'shareholders',
    permanentFilter: [
      {
        field: 'securityTokenId',
        operator: 'eq',
        value: project?.securityToken?.id,
      },
    ],
  });
  const {
    tableProps: securityTokenTransferEventTableProps,
    sorter: securityTokenTransferEventSorter,
  } = useTable<SecurityTokenTransferEvent>({
    dataProviderName: 'crud',
    resource: 'security-token-transfer-events',
    initialSorter: [
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
  });
  // write a useState for selected distribution
  const [selectedDistributionId, setSelectedDistributionId] =
    useState<number>(0);

  const { modalProps: dividendModalProps, show: dividendModalShow } =
    useModal();
  const { tableProps: distributionTableProps } = useTable<any>({
    dataProviderName: 'crud',
    resource: 'distributions',
  });
  const {
    drawerProps: createDistributionDrawerProps,
    formProps: createDistributionFormProps,
    saveButtonProps: createDistributionSaveButtonProps,
    show: createDistributionShow,
    onFinish: onFinishCreateDistribution,
    form: createDistributionForm,
  } = useDrawerForm<any>({
    action: 'create',
    dataProviderName: 'crud',
    resource: 'distributions',
    redirect: false,
  });
  const {
    drawerProps: editDistributionDrawerProps,
    formProps: editDistributionFormProps,
    saveButtonProps: editDistributionSaveButtonProps,
    show: editDistributionShow,
    onFinish: onFinishEditDistribution,
    form: editDistributionForm,
  } = useDrawerForm<any>({
    action: 'edit',
    dataProviderName: 'crud',
    resource: 'distributions',
    redirect: false,
  });
  const published = project?.status === ProjectAoStatusEnum.PUBLISHED;
  const handlePublishment = async () => {
    if (!project) {
      return;
    }
    setPulishmentLoading(true);
    mutateProjectStatus(
      {
        dataProviderName: 'crud',
        resource: 'project-status',
        id: project.id,
        values: {
          status: published
            ? ProjectAoStatusEnum.DRAFT
            : ProjectAoStatusEnum.PUBLISHED,
        },
      },
      {
        onSuccess: async () => {
          await queryResult.refetch();
          setPulishmentLoading(false);
        },
      }
    );
  };
  const onChange = (step: number) => {
    console.log('onChange:', current);
    setCurrent(step);
    switch (step) {
      case 0:
        break;
      case 1:
        editModalShow();
        break;
      case 2:
        break;
      default:
        break;
    }
  };

  return (
    <Show
      headerButtons={() => (
        <>
          <ListButton />
          <EditButton />
          <Button
            icon={<CheckOutlined />}
            onClick={handlePublishment}
            loading={pulishmentLoading}
          >
            {published ? 'Unpublish' : 'Publish'}
          </Button>
          {/* <Button type="primary">Deploy Security Token</Button> */}
          {/* <Button type="primary">Deploy Token Sale</Button> */}
          {/* <Button type="primary">Create Document</Button> */}
        </>
      )}
      isLoading={queryResult.isLoading}
      title="Project Detail"
      contentProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <Row gutter={[16, 16]} style={{}}>
        <Steps
          current={current}
          onChange={onChange}
          status={'process'}
          items={[
            {
              title: 'Set the Document',
              description: 'This is a description.',
            },
            {
              title: 'Deploy the Token',
              description: 'This is a description.',
            },
            {
              title: 'Set the Token Sale',
              description: 'This is a description.',
            },
          ]}
        />
        <Col xl={8} lg={24} xs={24}>
          <RcResizeObserver
            key="resize-observer"
            onResize={(offset) => {
              setResponsive(offset.width < 596);
            }}
          >
            <ProCard
              title={
                // @ts-ignore
                (project?.data?.content?.data as ProjectContent)?.attributes
                  .name
              }
              bordered
              headerBordered
              split={'horizontal'}
            >
              <ProCard title="Summary">{project?.summary}</ProCard>
              <ProCard split="vertical">
                <ProCard title="Security Token Contract Address">
                  <AddressLink
                    address={project?.securityToken?.contractAddress!}
                    chainId={project?.securityToken?.chainId!}
                    short={false}
                  />
                </ProCard>
                {/* <ProCard title="Chain"> */}
                {/*  {project?.data?.securityToken?.chainId} */}
                {/* </ProCard> */}
              </ProCard>
              <ProCard split="vertical">
                <ProCard title="Total Supply">
                  {project?.securityToken?.tokenSale &&
                    ethers.utils.commify(
                      ethers.utils.formatUnits(
                        project?.securityToken?.tokenSale?.totalSaleSupply,
                        project?.securityToken?.decimals
                      )
                    )}
                </ProCard>
                <ProCard title="Total Sold">
                  {project?.securityToken?.tokenSale &&
                    ethers.utils.commify(
                      ethers.utils.formatUnits(
                        project?.securityToken?.tokenSale?.totalSoldQuantity,
                        project?.securityToken?.decimals
                      )
                    )}
                </ProCard>
                <ProCard title="Decimals">
                  {project?.securityToken?.decimals}
                </ProCard>
              </ProCard>
              <ProCard split="vertical">
                <ProCard title="Token Sale Contract Address">
                  <AddressLink
                    address={
                      project?.securityToken?.tokenSale?.contractAddress!
                    }
                    chainId={project?.securityToken?.chainId!}
                    short={false}
                  />
                </ProCard>
                {/* <ProCard title="Chain"> */}
                {/*  {project?.data?.securityToken?.tokenSale?.chainId} */}
                {/* </ProCard> */}
              </ProCard>
              <ProCard split="vertical">
                <ProCard title="Type">{project?.type}</ProCard>
                <ProCard title="Min Buy Amount">
                  {project?.minimumPurchaseAmount &&
                    ethers.utils.commify(project?.minimumPurchaseAmount)}
                </ProCard>
                <ProCard title="Token Lock Time">
                  {project?.securityToken?.tokenSale
                    ?.initialOfferingLockSecond &&
                    dayjs
                      .duration(
                        project?.securityToken?.tokenSale
                          ?.initialOfferingLockSecond,
                        'seconds'
                      )
                      .humanize()}
                </ProCard>
              </ProCard>
              <ProCard split="vertical">
                <ProCard title="Sale Start Date">
                  {dayjs(
                    project?.securityToken?.tokenSale?.saleStartedAt
                  ).format('LLL')}
                </ProCard>
                <ProCard title="Sale End Date">
                  {dayjs(project?.securityToken?.tokenSale?.saleEndedAt).format(
                    'LLL'
                  )}
                </ProCard>
              </ProCard>
            </ProCard>
            <ProCard title="Security Token Vault">
              <AddressLink
                address={project?.securityToken?.tokenSale?.securityTokenVault!}
                chainId={project?.securityToken?.chainId!}
                short={false}
              />
            </ProCard>
            <ProCard title="Payment Vault">
              <AddressLink
                address={project?.securityToken?.tokenSale?.paymentVault!}
                chainId={project?.securityToken?.chainId!}
                short={false}
              />
            </ProCard>
            <ProCard split="vertical">
              <ProCard title="Document">
                {projectDocumentTemplate?.template_name}
              </ProCard>
              <ProCard title="Document approver email">
                {projectDocument?.approverEmail}
              </ProCard>
            </ProCard>
          </RcResizeObserver>
        </Col>
        <Col xl={16} xs={24}>
          <List
            breadcrumb={null}
            title="Shareholders"
            headerProps={{
              extra: <></>,
            }}
          >
            <Table {...shareholderTableProps} rowKey="id">
              <Table.Column dataIndex="name" title="User Name" />
              <Table.Column dataIndex="email" title="Email" />
              <Table.Column dataIndex="kycLevel" title="KYC Level" />
              <Table.Column
                dataIndex="balance"
                title="Balance"
                render={(value) => (
                  <TextField
                    value={numberFormat(
                      Number(ethers.utils.formatUnits(value))
                    )}
                  />
                )}
              />
              <Table.Column
                dataIndex="rate"
                title="Shareholding Rate"
                render={(value) => (
                  <TextField value={`${numberFormat(Number(value) * 100)}%`} />
                )}
              />
            </Table>
          </List>

          <List
            breadcrumb={null}
            title="Distribution Events"
            headerProps={{
              extra: (
                <CreateButton
                  onClick={() => createDistributionShow()}
                  resourceNameOrRouteName={'distributions'}
                />
              ),
            }}
          >
            <Table {...distributionTableProps} rowKey="id">
              <Table.Column
                dataIndex="distributionScheduleAt"
                title="Distribution Schedule At"
                key="distributionScheduleAt"
                render={(value) => <DateField value={value} format="LLL" />}
              />
              <Table.Column title={'Total Payout'} dataIndex="payout" />
              <Table.Column title={'Status'} dataIndex={'status'} />
              <Table.Column
                dataIndex="dividendEligibilityDeadline"
                title="Dividend Eligibility Deadline"
                key="dividendEligibilityDeadline"
                render={(value) => <DateField value={value} format="LLL" />}
              />
              <Table.Column<any>
                title="Actions"
                dataIndex="id"
                render={(value) => (
                  <Space>
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={value}
                      onClick={() => {
                        editDistributionShow(value);
                      }}
                    />
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={value}
                      onClick={() => {
                        setSelectedDistributionId(value);
                        dividendModalShow();
                      }}
                    />
                  </Space>
                )}
              />
            </Table>
          </List>

          <List
            breadcrumb={null}
            title="Transfer History"
            headerProps={{
              extra: <></>,
            }}
          >
            <Table {...securityTokenTransferEventTableProps} rowKey="id">
              <Table.Column
                dataIndex="transactionHash"
                title="Transaction Hash"
                render={(value) => (
                  <TransactionLink
                    transactionHash={value}
                    chainId={project?.securityToken?.chainId!}
                  />
                )}
              />
              <Table.Column<SecurityTokenTransferEvent>
                dataIndex={'from'}
                title="From"
                render={(value, record) => (
                  <AddressLink
                    address={value}
                    alias={record?.fromWallet?.member?.name}
                    chainId={project?.securityToken?.chainId!}
                  />
                )}
              />
              <Table.Column<SecurityTokenTransferEvent>
                dataIndex={'to'}
                title="To"
                render={(value, record) => (
                  <AddressLink
                    address={value}
                    alias={record?.toWallet?.member?.name}
                    chainId={project?.securityToken?.chainId!}
                  />
                )}
              />
              <Table.Column
                dataIndex="createdAt"
                title="Created At"
                key="createdAt"
                sorter
                defaultSortOrder={getDefaultSortOrder(
                  'createdAt',
                  securityTokenTransferEventSorter
                )}
                render={(value) => <DateField value={value} format="LLL" />}
              />
            </Table>
          </List>
        </Col>
      </Row>

      <Modal {...editModalProps}>
        <Form {...editFormProps} layout="vertical">
          <Button onClick={() => sendTransaction?.()}>Send Transaction</Button>
          {isLoading && <div>Check Wallet</div>}
          {isSuccess && <div>Transaction: {JSON.stringify(data)}</div>}
        </Form>
      </Modal>
      <Modal {...dividendModalProps} width={'60%'}>
        <DividendList distributionId={selectedDistributionId} />
      </Modal>
      <CreateDistribution
        drawerProps={createDistributionDrawerProps}
        formProps={createDistributionFormProps}
        saveButtonProps={createDistributionSaveButtonProps}
        onFinish={onFinishCreateDistribution}
        form={createDistributionForm}
      />
      <EditDistribution
        drawerProps={editDistributionDrawerProps}
        formProps={editDistributionFormProps}
        saveButtonProps={editDistributionSaveButtonProps}
        onFinish={onFinishEditDistribution}
        form={editDistributionForm}
      />
    </Show>
  );
};
