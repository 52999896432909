import {
  CalendarOutlined,
  MailOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Card,
  Col,
  DateField,
  getDefaultSortOrder,
  Grid,
  List,
  NumberField,
  Row,
  Show,
  Space,
  Table,
  TextField,
  Typography,
  useTable,
} from '@pankod/refine-antd';
import type {
  IResourceComponentsProps,
  ResourceRouterParams,
} from '@pankod/refine-core';
import { useApiUrl, useCustom, useRouterContext } from '@pankod/refine-core';
import type {
  Member,
  MemberDocument,
  Order,
  Wallet,
} from '@tokenbricks/talfa-backend-typescript-axios-client';
import dayjs from 'dayjs';
import { useNetwork } from 'wagmi';

import { AddressLink } from '../../components/Links';

const { useBreakpoint } = Grid;

// const { Title, Text } = Typography;

export const MemberShow: React.FC<IResourceComponentsProps> = () => {
  const { useParams } = useRouterContext();
  const { chains } = useNetwork();
  const { xl } = useBreakpoint();

  const { resource: routeResourceName, id: idFromRoute } =
    useParams<ResourceRouterParams>();
  const apiUrl = useApiUrl('crud');
  const { data, isLoading } = useCustom<Member>({
    url: `${apiUrl}/${routeResourceName}/${idFromRoute}`,
    method: 'get',
    config: {
      query: {
        join: 'wallets',
      },
    },
  });
  // const { data, isLoading } = queryResult;
  const member = data?.data;
  const { tableProps: memberDocumentTableProps, sorter: memberDocumentSorter } =
    useTable<MemberDocument>({
      dataProviderName: 'crud',
      resource: 'member-documents',
      permanentFilter: [
        {
          field: 'memberId',
          operator: 'eq',
          value: member?.id,
        },
      ],
      initialSorter: [
        {
          field: 'updatedAt',
          order: 'desc',
        },
      ],
    });
  const { tableProps: orderTableProps, sorter: orderSorter } = useTable<Order>({
    dataProviderName: 'crud',
    resource: 'orders',
    permanentFilter: [
      {
        field: 'memberId',
        operator: 'eq',
        value: member?.id,
      },
    ],
    initialSorter: [
      {
        field: 'updatedAt',
        order: 'desc',
      },
    ],
  });
  // const { data: categoryData, isLoading: categoryIsLoading } =
  //   useOne<ICategory>({
  //     resource: 'categories',
  //     id: record?.category.id || '',
  //     queryOptions: {
  //       enabled: !!record,
  //     },
  //   });

  return (
    <Show
      isLoading={isLoading}
      title="Member Detail"
      contentProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <Row gutter={[16, 16]} style={{}}>
        <Col xl={6} lg={24} xs={24}>
          <Card
            bordered={false}
            style={{
              height: '100%',
            }}
          >
            <Space
              direction="vertical"
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <Space
                direction="vertical"
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                {/* <Avatar size={120} src={user?.avatar?.[0].url}></Avatar> */}
                <Typography.Title level={3}>{member?.name}</Typography.Title>
              </Space>
              <Space
                direction="vertical"
                style={{
                  width: '100%',
                  textAlign: xl ? 'unset' : 'center',
                }}
              >
                <Typography.Text>
                  <MailOutlined />{' '}
                  <Badge
                    status={member?.emailVerified ? 'success' : 'error'}
                    text={member?.email}
                  />
                </Typography.Text>
                <Typography.Text>
                  <UserOutlined />{' '}
                  <Badge
                    status={member?.kycLevel !== 'NO_KYC' ? 'success' : 'error'}
                    text={member?.kycLevel.replaceAll('_', ' ')}
                  />
                </Typography.Text>
                <Typography.Text>
                  <CalendarOutlined /> {dayjs(member?.createdAt).format('LLL')}
                </Typography.Text>
              </Space>
            </Space>
          </Card>
        </Col>
        <Col xl={18} xs={24}>
          <List
            breadcrumb={null}
            title="Orders"
            headerProps={{
              extra: <></>,
            }}
          >
            <Table {...orderTableProps} rowKey="id">
              <Table.Column
                key="orderCode"
                dataIndex="orderCode"
                title="Order Code"
                render={(value) => <TextField value={value} />}
              />
              <Table.Column
                key="paymentType"
                dataIndex={'paymentType'}
                title={'Payment Type'}
                render={(value) => (
                  <TextField value={value.replaceAll('_', ' ')} />
                )}
              />
              <Table.Column
                align="right"
                key="price"
                dataIndex="price"
                title={'Price'}
                render={(value) => {
                  return (
                    <NumberField
                      options={{
                        currency: 'USD',
                        style: 'currency',
                        notation: 'compact',
                      }}
                      value={value}
                    />
                  );
                }}
              />
              <Table.Column
                align="right"
                key="amount"
                dataIndex="amount"
                title={'Amount'}
                // defaultSortOrder={getDefaultSortOrder('amount', sorter)}
                sorter
                render={(value) => {
                  return <NumberField value={value} />;
                }}
              />
              <Table.Column
                key="status"
                dataIndex={'status'}
                title={'Status'}
              />

              {/* <Table.Column<IOrder> */}
              {/*  key="products" */}
              {/*  dataIndex="products" */}
              {/*  title={t('orders.fields.products')} */}
              {/*  render={(_, record) => ( */}
              {/*    <Popover */}
              {/*      content={ */}
              {/*        <ul> */}
              {/*          {record.products.map((product) => ( */}
              {/*            <li key={product.id}>{product.name}</li> */}
              {/*          ))} */}
              {/*        </ul> */}
              {/*      } */}
              {/*      title="Products" */}
              {/*      trigger="hover" */}
              {/*    > */}
              {/*      {t('orders.fields.itemsAmount', { */}
              {/*        amount: record.products.length, */}
              {/*      })} */}
              {/*    </Popover> */}
              {/*  )} */}
              {/* /> */}
              <Table.Column
                key="paidAt"
                dataIndex="paidAt"
                title={'Paid At'}
                render={(value) =>
                  value ? <DateField value={value} format="LLL" /> : <></>
                }
                sorter
              />
              <Table.Column
                key="updatedAt"
                dataIndex="updatedAt"
                title={'Updated At'}
                render={(value) => <DateField value={value} format="LLL" />}
                sorter
                defaultSortOrder={getDefaultSortOrder('updatedAt', orderSorter)}
              />
            </Table>
          </List>
          <List
            breadcrumb={null}
            title="Documents"
            headerProps={{
              extra: <></>,
            }}
          >
            <Table {...memberDocumentTableProps} rowKey="id">
              <Table.Column
                key="projectId"
                dataIndex={'projectId'}
                title={'Project'}
              />
              <Table.Column
                key="status"
                dataIndex={'status'}
                title={'Status'}
              />
              <Table.Column
                key="updatedAt"
                dataIndex="updatedAt"
                title={'Updated At'}
                render={(value) => <DateField value={value} format="LLL" />}
                sorter
                defaultSortOrder={getDefaultSortOrder(
                  'updatedAt',
                  memberDocumentSorter
                )}
              />
            </Table>
          </List>
          <List
            title="Wallets"
            breadcrumb={null}
            headerProps={{
              extra: <></>,
              style: {
                marginTop: '1em',
              },
            }}
          >
            <Table pagination={false} dataSource={member?.wallets} rowKey="id">
              <Table.Column
                dataIndex="address"
                key="address"
                render={(value) => (
                  <AddressLink address={value} chainId={chains[0]?.id} />
                )}
              />
              <Table.Column<Wallet>
                dataIndex="isCustodial"
                key="isCustodial"
                title="Type"
                render={(value) => <>{value ? 'Custodial' : 'Non Custodial'}</>}
              />
              <Table.Column
                dataIndex="createdAt"
                key="createdAt"
                title="Created At"
                render={(value) => <DateField value={value} format="LLL" />}
              />
            </Table>
          </List>
        </Col>
      </Row>
    </Show>
  );
};
