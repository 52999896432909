import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Card, List, Table, useTable } from '@pankod/refine-antd';
import type { IResourceComponentsProps } from '@pankod/refine-core';
import { useQuery } from '@pankod/refine-core';
import { paymentProviderSettingAdminApi } from 'api/coreBackend/defHttp';

export const SettingPaymentList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    dataProviderName: 'customAPI',
    resource: 'setting-payments',
  });

  const { data: stripeOauthAuthorizeUrl } = useQuery(
    ['paymentProviderSettingAdminApi.getStripeOauthAuthorizeUrl'],
    async () => {
      return (
        (await paymentProviderSettingAdminApi.getStripeOauthAuthorizeUrl('/'))
          .data as any
      ).url;
    }
  );

  return (
    <List>
      <Card className="mb-6" title="Set Up Payment Providers">
        <p className=" text-slate-400">
          "Set Up Payment Providers" is a service that helps businesses and
          individuals to enable online payment options for their customers. With
          this service, businesses can easily integrate various payment
          providers such as Stripe into their website or mobile app. This allows
          their customers to make secure and convenient online transactions
          using their preferred payment method. The process of setting up
          payment providers can be complex, but with this service, businesses
          can save time and effort by outsourcing the task to experts. This
          ensures a smooth and seamless payment experience for both the business
          and their customers.
        </p>
      </Card>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          dataIndex="isSet"
          title="Status"
          render={(value) => (value ? <CheckOutlined /> : <CloseOutlined />)}
        />
        <Table.Column
          dataIndex="type"
          title="Setting"
          render={(value) => {
            if (value === 'STRIPE')
              return (
                <a href={stripeOauthAuthorizeUrl} target="_blank">
                  Link
                </a>
              );
            return <></>;
          }}
        />
      </Table>
    </List>
  );
};
