import { Create, Form, Input, Select, useForm } from '@pankod/refine-antd';
import type { IResourceComponentsProps } from '@pankod/refine-core';
import type { Wallet } from '@tokenbricks/talfa-backend-typescript-axios-client';
import React, { useState } from 'react';
import * as allChains from 'wagmi/chains';

export const WalletCreate: React.FC<IResourceComponentsProps> = () => {
  const [selectedGnosisSafe, setSelectedGnosisSafe] = useState<boolean>();
  const { formProps, saveButtonProps } = useForm<Wallet>();

  const selectOptions = Object.values(allChains).map((c) => ({
    label: c.name,
    value: c.name,
  }));
  const handleSelect = (name: string) => {
    const chain = Object.values(allChains).find((c) => c.name === name);
    formProps.form?.setFieldValue('chainId', chain?.id);
  };
  const handleSelectType = (type: string) => {
    setSelectedGnosisSafe(type === 'GNOSIS_SAFE');
  };
  return (
    <Create saveButtonProps={{ ...saveButtonProps }}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Type"
          name="type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            onSelect={handleSelectType}
            options={[
              { label: 'EOA', value: 'EOA' },
              { label: 'GNOSIS SAFE', value: 'GNOSIS_SAFE' },
            ]}
          />
        </Form.Item>
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        {selectedGnosisSafe && (
          <>
            <Form.Item
              label="Chain"
              name="chain-name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                showSearch
                onSelect={handleSelect}
                options={selectOptions}
              />
            </Form.Item>
            <Form.Item
              hidden
              label="Chain ID"
              name="chainId"
              rules={[
                {
                  required: true,
                },
              ]}
            ></Form.Item>
          </>
        )}
        <Form.Item label="Address" name="address" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};
