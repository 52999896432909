import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import { useState } from 'react';

interface Props {
  components: { uid: string; displayName: string }[];
  onClick: (uid: string) => void;
}

export default function DynamicAddButton({ components, onClick }: Props) {
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="flex justify-center">
        <Button
          className="mt-4 w-fit"
          shape="round"
          size="large"
          icon={show ? <CloseCircleOutlined /> : <PlusCircleOutlined />}
          onClick={() => {
            setShow((_show) => !_show);
          }}
        >
          {show ? 'Close' : 'Add a component to Sections'}
        </Button>
      </div>
      {show && (
        <Card
          className="mt-4 bg-black bg-opacity-[.02]"
          bodyStyle={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '16px',
            paddingBottom: '16px',
          }}
        >
          {components.map((component) => {
            return (
              <div
                className="first:mt-0 mt-4 cursor-pointer border border-slate-100 border-solid bg-white hover:bg-sky-50 rounded-lg px-4 py-2 transition-colors"
                onClick={() => {
                  onClick(component.uid);
                  setShow(false);
                }}
              >
                {component.displayName}
              </div>
            );
          })}
        </Card>
      )}
    </>
  );
}
