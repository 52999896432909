import { CheckCircleFilled } from '@ant-design/icons';
import {
  Button,
  Card,
  Collapse,
  TextField,
  useTable,
} from '@pankod/refine-antd';
import type { IResourceComponentsProps } from '@pankod/refine-core';
import { useQuery } from '@pankod/refine-core';
import { List } from 'antd';
import { documentManagementAdminApi } from 'api/coreBackend/defHttp';
import {
  setRedirectionUrlSteps,
  setWebhookUrlSteps,
} from 'views/setting/data/setZohoSignSteps';

const { Panel } = Collapse;

export const SettingDocumentList: React.FC<IResourceComponentsProps> = () => {
  const { data: zohoSignRedirectionUrl } = useQuery(
    ['documentManagementAdminApi.getZohoSignRedirectionUrl'],
    async () => {
      return (await documentManagementAdminApi.getZohoSignRedirectionUrl()).data
        .url;
    }
  );
  const { data: zohoSignWebhookUrl } = useQuery(
    ['documentManagementAdminApi.getZohoSignWebhookUrl'],
    async () => {
      return (await documentManagementAdminApi.getZohoSignWebhookUrl()).data
        .url;
    }
  );
  const { data: zohoSignAuthorizationUrl } = useQuery(
    ['documentManagementAdminApi.getZohoSignAuthorizationUrl'],
    async () => {
      return (await documentManagementAdminApi.getZohoSignAuthorizationUrl('/'))
        .data.url;
    }
  );

  const { tableProps } = useTable({
    dataProviderName: 'customAPI',
    resource: 'setting-documents',
  });

  return (
    <List>
      <Card
        className="mb-6"
        title={
          <div className="flex items-center">
            <span> Integrate Zoho Sign</span>
            {tableProps?.dataSource?.length && (
              <CheckCircleFilled className="text-2xl ml-4 text-green-500" />
            )}
          </div>
        }
      >
        <p className="text-slate-400">
          To set up your basic information in Zoho Sign, go to the Settings
          page. From there, you can enter the necessary details such as your
          redirection url, webhook url. These details will be used to identify
          you when you sign documents and communicate with other signers.
          Setting up your basic information is quick and easy - just enter the
          required information and you're ready to start signing documents using
          Zoho Sign.
        </p>
        <div className="text-slate-400">
          <div>
            <span>Redirection Url: </span>
            <TextField copyable value={zohoSignRedirectionUrl} />
            <Collapse className="mt-2" size="small">
              <Panel
                className="p-0"
                header="How to set up redirection URL:"
                key="1"
              >
                <List
                  dataSource={setRedirectionUrlSteps}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                />
              </Panel>
            </Collapse>
          </div>
          <div className="mt-4">
            <span>Webhook Url: </span>
            <TextField copyable value={zohoSignWebhookUrl} />
            <Collapse className="mt-2" size="small">
              <Panel
                className="p-0"
                header="How to set up webhook URL:"
                key="1"
              >
                <List
                  dataSource={setWebhookUrlSteps}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                />
              </Panel>
            </Collapse>
          </div>
        </div>

        <div className="mt-4">
          <Button
            type="primary"
            target="_blank"
            href={zohoSignAuthorizationUrl}
          >
            Set authorization
          </Button>
        </div>
      </Card>
    </List>
  );
};
