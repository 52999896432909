import {
  ArrowLeftOutlined,
  CloseOutlined,
  FlagFilled,
} from '@ant-design/icons';
import { Button, Drawer, Progress } from 'antd';
import classNames from 'classnames';
import type { Key } from 'react';
import { useEffect, useMemo, useState } from 'react';

import GuideContent from './component/GuideContent';
import GuideItem from './component/GuideItem';
import classes from './style/index.module.scss';

interface Step {
  key: Key;
  title: string;
  isCompleted: boolean;
  content: React.ReactNode;
}

const mockImage = [
  '/images/common/mock-guide.svg',
  '/images/common/mock-guide2.svg',
  '/images/common/mock-guide3.svg',
  '/images/common/mock-guide4.jpeg',
  '/images/common/mock-guide5.png',
];

export default function SetupGuide() {
  const [contentKey, setContentKey] = useState<Key>(1);
  const [open, setOpen] = useState(false);
  const [isListView, setIsListView] = useState(true);
  const showDrawer = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const mockSteps: Step[] = useMemo(() => {
    const steps: Step[] = [
      {
        key: 1,
        title: 'Set Supported Chains',
        isCompleted: false,
        content: (
          <GuideContent
            title="Set Supported Chains"
            paragraph="With our platform, you have the flexibility to choose which chains you want to support for transactions. Our easy-to-use interface makes it simple for you to manage your assets on the chains that matter most to you."
            image="/images/common/mock-guide.svg"
            button={{
              text: 'Set supported chain',
              href: '/settings/chains',
              onClick: handleClose,
            }}
          />
        ),
      },
      {
        key: 2,
        title: 'Add Wallets',
        isCompleted: true,
        content: (
          <GuideContent
            title="Add Wallets"
            paragraph="Integrating your wallets allows you to set up STO and accept payments from both personal wallets and multi-signature wallets. This makes it easier for you to manage your funds and receive payments from your customers."
            image="/images/common/mock-guide2.svg"
            button={{
              text: 'Add wallet',
              href: '/settings/wallets',
              onClick: handleClose,
            }}
          />
        ),
      },
      {
        key: 3,
        title: 'Set Up Payment Providers',
        isCompleted: false,
        content: (
          <GuideContent
            title="Set Up Payment Providers"
            paragraph="Connect your Stripe account and set your preferred stable coin address for receiving payments. Our platform makes it simple for you to make transactions and receive payments with confidence."
            image="/images/common/mock-guide3.svg"
            button={{
              text: 'Set payment provider',
              href: '/settings/payments',
              onClick: handleClose,
            }}
          />
        ),
      },
      {
        key: 4,
        title: 'Integrate Zoho Sign (optional)',
        isCompleted: false,
        content: (
          <GuideContent
            title="Integrate Zoho Sign (optional)"
            paragraph="Streamline your document signing process with our optional integration with Zoho Sign. Save time and make your signature process more efficient."
            image="/images/common/mock-guide4.jpeg"
            button={{
              text: 'Bind Zoho Sign',
              href: '/settings/documents',
              onClick: handleClose,
            }}
          />
        ),
      },
      {
        key: 5,
        title: 'Customize Your Domain Name (optional)',
        isCompleted: false,
        content: (
          <GuideContent
            title="Customize Your Domain Name (optional)"
            paragraph="Personalize your platform to reflect your brand by customizing your domain. Make it your own and stand out from the rest with a domain that's uniquely yours."
            image="/images/common/mock-guide5.png"
            button={{ text: 'Add domain', href: '#', onClick: handleClose }}
          />
        ),
      },
    ];
    return steps;
  }, []);

  const content = mockSteps.find((step) => step.key === contentKey)?.content;

  const completedStepCount = mockSteps.filter(
    (step) => step.isCompleted === true
  ).length;
  const totalStepCount = mockSteps.length;

  const title = (
    <div>
      <div className="flex items-center justify-between">
        {isListView && <h3 className="m-0 text-lg">Setup guide</h3>}
        {!isListView && (
          <div className="flex items-center gap-3">
            <Button
              icon={<ArrowLeftOutlined />}
              type="text"
              onClick={() => {
                setIsListView(true);
              }}
            />
            <h5 className="mb-0 text-slate-500 font-normal">View all tasks</h5>
          </div>
        )}
        <Button type="text" icon={<CloseOutlined />} onClick={handleClose} />
      </div>
      <div>
        <Progress
          className={classNames(classes.progress, 'm-0')}
          percent={(completedStepCount / totalStepCount) * 100}
          showInfo={false}
        />
      </div>
      {isListView && (
        <p className="font-normal text-sm text-gray-600">
          {completedStepCount} of {totalStepCount} tasks complete
        </p>
      )}
    </div>
  );

  // 圖片預加載
  useEffect(() => {
    mockImage.forEach((image) => {
      new Image().src = image;
    });
  }, []);

  return (
    <div>
      <div
        className="flex items-center gap-2 cursor-pointer"
        onClick={showDrawer}
      >
        <FlagFilled className="text-[#27a0ac]" />
        <span>Setup guide</span>
      </div>
      <Drawer
        title={title}
        headerStyle={{ flexDirection: 'row-reverse', paddingBottom: '0px' }}
        bodyStyle={{ padding: 0 }}
        placement="right"
        closable={false}
        onClose={handleClose}
        open={open}
      >
        <>
          {isListView && (
            <div className="flex flex-col pt-2">
              {mockSteps.map((step) => (
                <GuideItem
                  key={step.key}
                  isCompleted={step.isCompleted}
                  onClick={() => {
                    setIsListView(false);
                    setContentKey(step.key);
                  }}
                >
                  {step.title}
                </GuideItem>
              ))}
            </div>
          )}
          {!isListView && content}
        </>
      </Drawer>
    </div>
  );
}
