import type { Chain } from 'wagmi';
import * as allChains from 'wagmi/chains';

export function getChainById(id: number): Chain {
  const chain = Object.values(allChains).find((_chain) => _chain.id === id);

  if (!chain) {
    throw new Error(`Chain with id ${id} not found`);
  }
  return chain;
}
