import {
  DateField,
  DeleteButton,
  EditButton,
  getDefaultSortOrder,
  Input,
  List,
  Space,
  Table,
  useTable,
} from '@pankod/refine-antd';
import type { IResourceComponentsProps } from '@pankod/refine-core';
import type { Chain } from '@tokenbricks/talfa-backend-typescript-axios-client';

export const ChainList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<Chain>({
    initialSorter: [
      {
        field: 'updatedAt',
        order: 'desc',
      },
    ],
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="chainId" title="Chain ID" />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          dataIndex="blockConfirmations"
          title="Block Confirmations"
        />
        <Table.Column
          dataIndex="alchemyApiKey"
          title="Alchemy API Key"
          render={(value) => (
            <Input.Password
              style={{
                // @ts-ignore
                'caret-color': 'transparent',
              }}
              visibilityToggle
              bordered={false}
              value={value}
            />
          )}
        />
        <Table.Column
          dataIndex="updatedAt"
          title="Updated At"
          key="updatedAt"
          sorter
          defaultSortOrder={getDefaultSortOrder('updatedAt', sorter)}
          render={(value) => <DateField value={value} format="LLL" />}
        />
        <Table.Column
          dataIndex="createdAt"
          title="Created At"
          key="createdAt"
          sorter
          // defaultSortOrder={getDefaultSortOrder('createdAt', sorter)}
          render={(value) => <DateField value={value} format="LLL" />}
        />
        <Table.Column<Chain>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
