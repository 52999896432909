import { CheckCircleFilled, RightOutlined } from '@ant-design/icons';

import { GrayCircleIcon } from '../GrayCircleIcon';

interface Props {
  children: string | React.ReactNode;
  isCompleted: boolean;
  onClick: () => void;
}

export default function GuideItem({ children, isCompleted, onClick }: Props) {
  return (
    <div
      className="flex px-5 items-center py-4 cursor-pointer hover:bg-slate-100"
      onClick={onClick}
    >
      {isCompleted ? (
        <CheckCircleFilled className="text-2xl text-blue-700" />
      ) : (
        <GrayCircleIcon />
      )}
      <div className="font-medium text-sm ml-2 ">{children}</div>
      <RightOutlined className="ml-auto text-slate-500" />
    </div>
  );
}
