import '../style/styles.css';

import { AntdLayout, Card, Col, Row, Typography } from '@pankod/refine-antd';
import React from 'react';

const { Title } = Typography;

export interface IRegisterForm {
  name: string;
}

export const SetNameSuccess: React.FC = () => {
  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <img
                className="h-52"
                src="/images/logo/logo-white.svg"
                alt="talfa Logo"
              />
            </div>
            <Card headStyle={{ borderBottom: 0 }}>
              <div className="mt-auto py-12 flex flex-col items-center gap-3 text-center">
                <img
                  src="/images/common/success.png"
                  alt="success"
                  width={40}
                  height={40}
                />
                <Title level={3} className="title">
                  Waiting for approval
                </Title>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
